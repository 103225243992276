import { useEffect, useState } from "react";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { Helmet } from "react-helmet";
import { Link, NavLink, useParams } from "react-router-dom";
import { BlogApi } from "../../AAL/blog-api";
import AppImage from "../../components/image/img";
import PageLoading from "../../components/page-loading/page-loading";
import { GetPhoto } from "../../configs/api-config";
import { IBlogModel } from "../../models/blog-models";

export default function BlogViewPage() {
    const { id } = useParams();
    const [blog, setBlog] = useState<IBlogModel>();
    const [blogs, setBlogs] = useState<IBlogModel[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (id) {
            BlogApi.get(id)
                .then((b) => setBlog(b))
                .catch()
                .finally(() => setLoading(false));

            BlogApi.getAll()
                .then((blogs) => {
                    let bs = [...blogs];
                    let currentIndex = bs.findIndex((x) => x.slug === id);
                    if (currentIndex >= 0) {
                        bs.splice(currentIndex, 1);
                    }
                    setBlogs(bs?.sort((a, b) => a.order - b.order));
                })
                .catch(() => {})
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [id]);

    return (
        <Container>
            <Helmet>
                <title>{blog?.pageTitle}</title>
                <meta name="keywords" content={blog?.metaKeywords} />
                <meta name="description" content={blog?.metaDescription} />
            </Helmet>
            {/**Breadcrumb */}
            <nav className="mt-2 mb-2">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <NavLink to="/" className="text-primary">
                            Home
                        </NavLink>
                    </li>
                    <li className="breadcrumb-item">
                        <NavLink to="/blog" className="text-primary">
                            Blog
                        </NavLink>
                    </li>
                    <li className="breadcrumb-item active">{blog?.title}</li>
                </ol>
            </nav>

            {loading ? (
                <PageLoading />
            ) : (
                <div className="text-center">
                    {blog?.headerImage ? (
                        <AppImage
                            className="img-fluid"
                            name={blog?.headerImage}
                        />
                    ) : (
                        <></>
                    )}

                    <h2 className="mt-4">{blog?.title}</h2>
                    <p className="lead">{blog?.subTitle}</p>
                    <div className="text-start d-flex flex-row gap-4">
                        <small className=" text-muted">
                            {blog?.addedAt?.toLocaleDateString()}
                        </small>

                        <small>{blog?.subject}</small>
                    </div>
                    <div
                        className="mt-4"
                        style={{
                            textAlign: "justify",
                        }}
                        dangerouslySetInnerHTML={{
                            __html: blog?.content ?? "",
                        }}
                    ></div>
                </div>
            )}
            <hr className="my-5" />
            {blogs?.length ? (
                <div className="mt-4">
                    <h4 className=" mb-4 text-center">
                        Bekijk onze andere blogs
                    </h4>
                    <Row className="g-2 g-lg-4">
                        {blogs.map((i) => (
                            <Col
                                key={i.id}
                                className="mb-2 mb-lg-4"
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                            >
                                <Link
                                    to={"/blog/" + i.slug}
                                    className="blog-link"
                                >
                                    <Card className="h-100">
                                        <Card.Img src={GetPhoto(i.thumbnail)} />
                                        <Card.Body className="text-left">
                                            <div className="d-flex flex-row justify-content-between gap-2">
                                                <small className="blog-card-date">
                                                    {i.addedAt?.toLocaleDateString()}
                                                </small>{" "}
                                                <small className="blog-card-date">
                                                    {i.subject}
                                                </small>
                                            </div>
                                            <p className="lead mb-0">
                                                {i.title}
                                            </p>
                                            <p>{i.subTitle}</p>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </div>
            ) : (
                <></>
            )}
        </Container>
    );
}
