import { IRegionalHomePageModel } from "../models/regional-page-models";
import { BaseApi } from "./base-api";

export class RegionalHomePagesApi extends BaseApi {
    private static url = (api: string) => `regionalpage/${api}`;

    /**
     * Get 
     * @param id  id
     * @returns Regional Page
     */
    public static async get(id: number): Promise<IRegionalHomePageModel> {
        return this._get(this.url('get'), {
            id
        });
    }

    /**
     * Get 
     * @param slug slug
     * @returns Regional Page
     */
    public static async getBySlug(slug: string): Promise<IRegionalHomePageModel> {
        return this._get(this.url('getBySlug'), {
            slug
        });
    }

    /**
     * Get all pages
     * @returns List of pages
     */
    public static async getAll(): Promise<IRegionalHomePageModel[]> {
        return this._get(this.url('getall'));
    }
}