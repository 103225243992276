import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/esm/Spinner";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { MiscApi } from "../../AAL/misc-api";
import { IPageContent } from "../../models/shared-models";
export default function DisclaimerPage() {
    const [seoContent, setSeoContent] = useState<IPageContent>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            setLoading(true);
            const data = await MiscApi.getDisclaimerPageContent();
            setSeoContent(data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Container>
                {seoContent ? (
                    <Helmet>
                        <title>{seoContent?.title}</title>
                        <meta
                            name="description"
                            content={seoContent.description}
                        />
                        <meta name="keywords" content={seoContent.keywords} />
                    </Helmet>
                ) : undefined}
                {/**Breadcrumb */}
                <nav className="mt-2 mb-2">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <NavLink to="/" className="text-primary">
                                Home
                            </NavLink>
                        </li>
                        <li className="breadcrumb-item active">Disclaimer</li>
                    </ol>
                </nav>

                {loading ? (
                    <div className="text-center my-5">
                        <Spinner variant="primary" className="my-5" />
                    </div>
                ) : (
                    <div
                        className="mt-5 ql-editor"
                        dangerouslySetInnerHTML={{
                            __html: seoContent?.content ?? "",
                        }}
                    ></div>
                )}
            </Container>
        </>
    );
}
