import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { NavLink } from "react-router-dom";
import { OrderApi } from "../../AAL/order-api";
import { OrderStatus } from "../../enums/order-status";
import { HandleError } from "../../errors/handler";
import { IOrderModel } from "../../models/order-model";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
import PageLoading from "../../components/page-loading/page-loading";
import ViewOrderModal from "../../components/modals/view-order-modal";
import { Helmet } from "react-helmet";

export default function MyOrdersPage() {
    const [orders, setOrders] = useState<IOrderModel[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showOrder, setShowOrder] = useState<IOrderModel>();
    const [error, setError] = useState<string>("");
    useEffect(() => {
        OrderApi.getAll()
            .then((o) => setOrders(o))
            .catch((error) => setError(HandleError(error, false)[""]))
            .finally(() => setLoading(false));
    }, []);

    const downloadInvoice = async (orderId: number) => {
        try {
            const reponse: any = await OrderApi.downloadInvoice(orderId);
            console.log("Data", reponse);
            const link = document.createElement("a");
            link.href = URL.createObjectURL(
                new Blob([reponse.data], {
                    type: "application/pdf",
                })
            );
            link.download = "invoice_" + new Date().getTime() + ".pdf";
            document.body.append(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(link.href), 1000);
        } catch (error) {
            console.log(error);
            setError(HandleError(error, false)[""]);
        }
    };

    return (
        <Container>
            <Helmet>
                <title>MIjn Reparatie</title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
            </Helmet>
            {/**Breadcrumb */}
            <nav className="mt-2 mb-2">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <NavLink to="/" className="text-primary">
                            Home
                        </NavLink>
                    </li>
                    <li className="breadcrumb-item active">My Orders</li>
                </ol>
            </nav>
            <div className="mt-4">
                <h4>Your Order History</h4>
                {error ? (
                    <div className="alert alert-danger">
                        <p>{error}</p>
                    </div>
                ) : undefined}

                {loading ? (
                    <PageLoading />
                ) : (
                    <Table className="order-table" align="center">
                        <thead>
                            <tr>
                                <th>Order Number</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders?.length ? (
                                orders.map((order) => (
                                    <tr key={order.id}>
                                        <td className="">
                                            {order.orderNumber}
                                        </td>
                                        <td>
                                            {order.addedAt.toLocaleString()}
                                        </td>
                                        <td>{OrderStatus[order.status]}</td>
                                        <td>
                                            <Button
                                                title="View Details"
                                                className="rounded-0 m-2 text-white"
                                                size="sm"
                                                variant="success"
                                                onClick={() =>
                                                    setShowOrder(order)
                                                }
                                            >
                                                <FontAwesomeIcon icon={faEye} />
                                            </Button>

                                            {order.invoice ? (
                                                <a
                                                    onClick={() =>
                                                        downloadInvoice(
                                                            order.id
                                                        )
                                                    }
                                                    className="btn btn-sm btn-primary rounded-0 m-2"
                                                    title="Download invoice"
                                                    target="_blank"
                                                    download
                                                    rel="noreferrer"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faFileDownload}
                                                    />
                                                </a>
                                            ) : undefined}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <p className="text-center my-4">
                                    You have not placed any order yet...
                                </p>
                            )}
                        </tbody>
                    </Table>
                )}
            </div>
            <ViewOrderModal
                onHide={() => setShowOrder(undefined)}
                item={showOrder}
                show={showOrder ? true : false}
            />
        </Container>
    );
}
