/* eslint-disable jsx-a11y/anchor-is-valid */
import Card from "react-bootstrap/Card";
import { NavLink } from "react-router-dom";
import "./styles.scss";
export type TMenuItem = {
    text: string;
    active?: boolean;
    to: string;
};
export type TProps = {
    title?: string;
    list: TMenuItem[];
};
export default function SideNavMenuList(props: TProps) {
    return (
        <Card className="h-100">
            <p className="text-bold p-2 mb-0">{props.title}</p>
            <div className="menu-list-container">
                <ul className="list-unstyled menu-list">
                    {props.list.map((m, i) => (
                        <li
                            key={i}
                            className={`d-flex flex-column ${
                                m.active ? "active" : ""
                            }`}
                        >
                            <NavLink to={m.to}>{m.text}</NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </Card>
    );
}
