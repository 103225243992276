import "./styles.scss";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useNavigate } from "react-router-dom";
type TStepProgressProps = {
    currentStep: 1 | 2 | 3 | 4;
};
export default function StepProgress(props: TStepProgressProps) {
    const navigate = useNavigate();
    return (
        <>
            <ProgressBar
                style={{
                    height: 10,
                }}
                className="mt-2 mb-2"
            >
                {[1, 2, 3, 4].map((p) => (
                    <ProgressBar
                        key={p}
                        now={25}
                        style={
                            p !== props.currentStep
                                ? {
                                      backgroundColor: "#e9ecef",
                                  }
                                : {}
                        }
                    />
                ))}
            </ProgressBar>

            <div className="d-flex flex-row justify-content-evenly step-text">
                <p
                    className={`text-center ${
                        props.currentStep > 1
                            ? "text-primary cursor-pointer"
                            : ""
                    }`}
                    onClick={() => {
                        if (props.currentStep > 1) {
                            console.log("Nav", Number(props.currentStep) - 1);
                            navigate(-(Number(props.currentStep) - 1));
                        }
                    }}
                >
                    Selecteer Merk
                </p>
                <p
                    className={`text-center ${
                        props.currentStep > 2
                            ? "text-primary cursor-pointer"
                            : ""
                    }`}
                    onClick={() => {
                        if (props.currentStep > 2) {
                            console.log(
                                "nav",
                                -(Number(props.currentStep) - 2)
                            );
                            navigate(-(Number(props.currentStep) - 2));
                        }
                    }}
                >
                    Selecteer Model
                </p>
                <p
                    className={`text-center ${
                        props.currentStep > 3
                            ? "text-primary cursor-pointer"
                            : ""
                    }`}
                    onClick={() => {
                        if (props.currentStep > 3) {
                            console.log(
                                "nav",
                                -(Number(props.currentStep) - 3)
                            );
                            navigate(-(Number(props.currentStep) - 3));
                        }
                    }}
                >
                    Selecteer Reparatie
                </p>
                <p
                    className={`text-center ${
                        props.currentStep > 4
                            ? "text-primary cursor-pointer"
                            : ""
                    }`}
                    onClick={() => {
                        if (props.currentStep > 4) {
                            console.log(
                                "nav",
                                -(Number(props.currentStep) - 4)
                            );
                            navigate(-(Number(props.currentStep) - 4));
                        }
                    }}
                >
                    Uw gegevens
                </p>
            </div>
        </>
    );
}
