export const USR_LOCALSTORAGE_KEY = 'USER_C';

export const TimeTakeOptions = [
    "30 min",
    "60 min",
    "90 min",
    "180 min",
    "240 min",
    "1 dag",
    "In overleg"
]

export const BRANDS_PER_PAGE_COUNT = 30;
export const MODELS_PER_PAGE = 30;
export const BLOGS_PER_PAGE = 12;
export const CURRENCYSIGN = "";
export const ALLOWED_ORDER_NEXT_DAYS = 10;