import { IRepairItemModel } from "../models/repair-item-models";
import { BaseApi } from "./base-api";

export class RepairItemApi extends BaseApi {
    private static url = (api: string) => `repairitem/${api}`;

    /**
     * Get RepairItem
     * @param id RepairItem  id
     * @returns RepairItem Object
     */
    public static async get(id: number): Promise<IRepairItemModel> {
        return this._get(this.url('get'), {
            id
        });
    }

    /**
     * Get all RepairItems
     * @returns List of RepairItems
     */
    public static async getAll(): Promise<IRepairItemModel[]> {
        return this._get(this.url('getall'));
    }

    /**
     * Get all RepairItems by product 
     * @returns List of RepairItems
     */
    public static async getAllByProduct(productId: number): Promise<IRepairItemModel[]> {
        return this._get(this.url('getallbyproduct'), {
            productId
        });
    }

    /**
     * Get all RepairItems filtered
     * @returns List of RepairItems
     */
    public static async getAllFiltered(productId: number, categoryId: number): Promise<IRepairItemModel[]> {
        return this._get(this.url('getallfiltered'), {
            productId,
            categoryId
        });
    }
}