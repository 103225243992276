import { Navigate, createBrowserRouter } from "react-router-dom";
import AuthorizedOnly from "./components/guard/check-auth";
import AboutUsPage from "./pages/about-us";
import BlogsPage from "./pages/blog";
import BlogViewPage from "./pages/blog/blog-view";
import BrandsPage from "./pages/brands";
import ConfirmOrderPage from "./pages/confirm-order";
import ContactPage from "./pages/contact";
import DisclaimerPage from "./pages/disclaimer";
import FAQPage from "./pages/faq";
import ForBusinessPage from "./pages/for-business";
import HomePage from "./pages/home";
import LandingMaster from "./pages/landing";
import LoginPage from "./pages/login/login";
import ModelsPage from "./pages/models";
import MyOrdersPage from "./pages/my-orders";
import PrivacyPolicyPage from "./pages/privacy-policy";
import RepairsPage from "./pages/repairs";
import TermsConditionPage from "./pages/terms-condition";
import ServiceAreasPage from "./pages/service-areas";
export const Routes = createBrowserRouter([
    {
        path: "/",
        element: <LandingMaster />,
        children: [
            {
                path: "/",
                element: <HomePage />,
            },
            {
                path: "/region/:area",
                element: <HomePage />,
            },
            {
                path: "brands",
                element: <BrandsPage />,
            },
            {
                path: "/:type/brands",
                element: <BrandsPage />,
            },
            {
                path: "models/:type/:brand",
                element: <ModelsPage />,
            },
            {
                path: "models/:brand",
                element: <ModelsPage />,
            },
            {
                path: "reparatie/:slug",
                element: <RepairsPage />,
            },
            {
                path: "bevestiging",
                element: <ConfirmOrderPage />,
            },
            {
                path: "over-ons",
                element: <AboutUsPage />,
            },
            {
                path: "contact",
                element: <ContactPage />,
            },
            {
                path: "zakelijk",
                element: <ForBusinessPage />,
            },
            {
                path: "faq",
                element: <FAQPage />,
            },
            {
                path: "privacy-policy",
                element: <PrivacyPolicyPage />,
            },
            {
                path: "disclaimer",
                element: <DisclaimerPage />,
            },
            {
                path: "algemene-voorwaarden",
                element: <TermsConditionPage />,
            },
            {
                path: "login",
                element: <LoginPage />,
            },
            {
                path: "blog",
                element: <BlogsPage />,
            },
            {
                path: "blog/:id",
                element: <BlogViewPage />,
            },
            {
                path: "regio",
                element: <ServiceAreasPage />,
            },
            {
                path: "my-orders",
                element: (
                    <AuthorizedOnly>
                        <MyOrdersPage />
                    </AuthorizedOnly>
                ),
            },
        ],
        errorElement: <Navigate to={"/"} />,
    },
]);
