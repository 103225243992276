import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

type TErrorModalProps = {
    show: boolean;
    title?: string;
    message: string;
    onClose?: () => void;
};
export default function ErrorModal(props: TErrorModalProps) {
    return (
        <Modal centered onHide={() => props.onClose?.()} show={props.show}>
            <Modal.Header className="border-0 mb-0"></Modal.Header>
            <Modal.Body className="pt-0">
                <div className="text-center">
                    <FontAwesomeIcon
                        icon={faXmarkCircle}
                        size="2x"
                        color="#f00"
                    />
                </div>
                <p className="lead text-center text-danger mt-2">
                    {props.title}
                </p>
                <p className="text-center">{props.message}</p>
                <div className="mt-3 text-center">
                    <Button
                        variant="primary my-2"
                        onClick={() => props.onClose?.()}
                    >
                        OK
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
