import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from "react";
import AuthApi from "../AAL/auth-api";
import { USR_LOCALSTORAGE_KEY } from "../configs/constants";
import { HandleError } from "../errors/handler";
import { IUser } from "../models/user-model";

interface AuthContextType {
    user?: IUser;
    error?: string;
    loading: boolean;
    login: (email: string, code: string) => Promise<void>;
    logout: () => void;
    sendCode: (email: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({
    children,
}: {
    children: ReactNode;
}): JSX.Element {
    const usrData = localStorage.getItem(USR_LOCALSTORAGE_KEY);
    const [user, setUser] = useState<IUser | undefined>(
        usrData ? JSON.parse(usrData) : undefined
    );
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const sendCode = async (email: string) => {
        try {
            setLoading(true);
            await AuthApi.sendCode(email);
        } catch (error) {
            setError(HandleError(error, false));
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const login = async (email: string, code: string) => {
        try {
            setLoading(true);
            const resp = await AuthApi.verifyCode(email, code);
            localStorage.setItem(USR_LOCALSTORAGE_KEY, JSON.stringify(resp));
            setUser(resp);
        } catch (error) {
            setError(HandleError(error, false));
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const logout = () => {
        try {
            localStorage.removeItem(USR_LOCALSTORAGE_KEY);
            setUser(undefined);
        } catch (error) {}
    };

    const memoedValue = useMemo(
        () => ({
            user,
            error,
            loading,
            login,
            logout,
            sendCode,
        }),
        [user, error, loading]
    );

    return (
        <AuthContext.Provider value={memoedValue}>
            {children}
        </AuthContext.Provider>
    );
}

export default function useAuth() {
    return useContext(AuthContext);
}
