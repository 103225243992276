import { GetPhoto } from "../../configs/api-config";

interface IAppImageProps
    extends React.DetailedHTMLProps<
        React.HtmlHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
    > {
    name: string;
}
export default function AppImage(props: IAppImageProps) {
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img {...props} src={GetPhoto(props.name)} />;
}
