import { AppError } from "./app-error";

export function HandleError(error: AppError | any, showPopup: boolean = true): any {
    let message: string = '';

    switch (error.status) {
        case 400:
            if (error?.data) {
                const errors = parseErrors(error.data);
                if (showPopup) {


                }
                return errors;
            } else {
                return {
                    "": "Unknow error."
                };
            }
        default:
            message = error?.message ?? 'Unknow error occured.'
    }

    if (showPopup) {

    }
    return error.message ?? 'An error has occured.';
}


function parseErrors(data: any) {
    let errors: any = {};
    let parseObj: any = data;
    if (data?.errors) {
        parseObj = data.errors;
    }
    for (let key in parseObj) {
        errors[key] = parseObj[key][0]
    }
    return errors;
}