import { useEffect, useState } from "react";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { Helmet } from "react-helmet";
import ReactPaginate from "react-paginate";
import { Link, NavLink } from "react-router-dom";
import { BlogApi } from "../../AAL/blog-api";
import PageLoading from "../../components/page-loading/page-loading";
import { GetPhoto } from "../../configs/api-config";
import { BLOGS_PER_PAGE } from "../../configs/constants";
import { IBlogModel } from "../../models/blog-models";
import "./index.css";

export default function BlogsPage() {
	const [loading, setLoading] = useState<boolean>(true);

	const [blogs, setBlogs] = useState<IBlogModel[]>([]);
	const [offset, setOffset] = useState<number>(0);
	const pageCount = Math.ceil(blogs.length / BLOGS_PER_PAGE);
	const endOffset = offset + BLOGS_PER_PAGE;
	const currentPageItems = blogs.slice(offset, endOffset);

	const handlePageClick = (event: { selected: number }) => {
		const newOffset = (event.selected * BLOGS_PER_PAGE) % blogs.length;
		setOffset(newOffset);
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		BlogApi.getAll()
			.then((blogs) => setBlogs(blogs?.sort((a, b) => a.order - b.order)))
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	return (
		<Container>
			{/**Breadcrumb */}
			<nav className="mt-2 mb-2">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<NavLink to="/" className="text-primary">
							Home
						</NavLink>
					</li>
					<li className="breadcrumb-item active">Blogs</li>
				</ol>
			</nav>
			<Helmet>
				<title>Onze Blogs</title>
			</Helmet>
			<h4 className="text-center">Onze Blogs</h4>
			{loading ? (
				<PageLoading />
			) : currentPageItems?.length ? (
				<>
					<Row className="g-2 g-lg-4">
						{currentPageItems.map((i) => (
							<Col
								key={i.id}
								className="mb-2 mb-lg-4"
								xs={12}
								sm={6}
								md={4}
								lg={3}
							>
								<Card className="h-100">
									<Card.Img src={GetPhoto(i.thumbnail)} />
									<Card.Body className="text-left">
										<div className="d-flex flex-row justify-content-between gap-2">
											<small className="blog-card-date">
												{i.addedAt?.toLocaleDateString()}
											</small>{" "}
											<small className="blog-card-date">
												{i.subject}
											</small>
										</div>
										<p className="lead mb-0">{i.title}</p>
										<p>{i.subTitle}</p>
										<p
											dangerouslySetInnerHTML={{
												__html: i.content ?? "",
											}}
											className=" small mb-0"
											style={{
												maxHeight: 100,
												overflow: "hidden",
												textOverflow: "ellipsis",
												maxLines: 4,
											}}
										></p>
										<p>...</p>
									</Card.Body>
									<Card.Footer>
										<Link
											to={"/blog/" + i.slug}
											className="btn btn-primary rounded-0 btn-sm"
										>
											Lees verder
										</Link>
									</Card.Footer>
								</Card>
							</Col>
						))}
					</Row>

					<div className="d-flex justify-content-center mt-4 mb-4">
						<ReactPaginate
							breakLabel="..."
							nextLabel=">>"
							onPageChange={handlePageClick}
							pageRangeDisplayed={5}
							pageCount={pageCount}
							previousLabel="<<"
							containerClassName="pagination"
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							nextClassName="page-item"
							previousLinkClassName="page-link"
							nextLinkClassName="page-link"
							activeClassName="active"
						/>
					</div>
				</>
			) : (
				<p className="text-center my-5">
					No models found for selected brand...
				</p>
			)}
		</Container>
	);
}
