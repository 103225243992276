import { IProductModel } from "../models/product-models";
import { BaseApi } from "./base-api";

export class ProductApi extends BaseApi {
    private static url = (api: string) => `product/${api}`;

    /**
     * Get Product
     * @param id Product id
     * @returns Product Object
     */
    public static async get(id: number): Promise<IProductModel> {
        return this._get(this.url('get'), {
            id
        });
    }

    /**
     * Get Product
     * @param slug 
     * @returns Product Object
     */
    public static async getBySlug(slug: string): Promise<IProductModel> {
        return this._get(this.url('getbyslug'), {
            slug
        });
    }

    /**
     * Get all product
     * @returns List of Product
     */
    public static async getAll(): Promise<IProductModel[]> {
        return this._get(this.url('getall'));
    }

    /**
     * Get all products by brand and typeid
     * @returns List of Product
     */
    public static async getAllByBrandAndType(brandId: number, typeId?: number): Promise<IProductModel[]> {
        return this._get(this.url('GetAllByBrandAndType'), {
            brandId,
            typeId
        });
    }

    /**
     * Get all products by brand and typeid
     * @returns List of Product
     */
    public static async getAllByBrandAndTypeSlug(brand: string, type?: string): Promise<IProductModel[]> {
        return this._get(this.url('GetAllByBrandAndTypeSlug'), {
            brand,
            type
        });
    }
}