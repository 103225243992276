import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

type TSuccessModalProps = {
    show: boolean;
    title?: string;
    message: string;
    onClose?: () => void;
    showCloseBtn?: boolean;
    footerText?: string;
};
export default function SuccessModal(props: TSuccessModalProps) {
    return (
        <Modal centered onHide={() => props.onClose?.()} show={props.show}>
            <Modal.Header className="border-0 mb-0"></Modal.Header>
            <Modal.Body className="pt-0">
                <div className="text-center">
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        size="2x"
                        className="text-primary"
                    />
                </div>
                <p className="lead text-center text-primary mt-2">
                    {props.title}
                </p>
                <p className="text-center">{props.message}</p>
                {props.footerText ? (
                    <p className="mt-5 mb-4 text-center text-primary">
                        {props.footerText}
                    </p>
                ) : undefined}

                {props.showCloseBtn ? (
                    <div className="mt-3 text-center">
                        <Button
                            variant="primary my-2"
                            onClick={() => props.onClose?.()}
                        >
                            OK
                        </Button>
                    </div>
                ) : undefined}
            </Modal.Body>
        </Modal>
    );
}
