import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./styles.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    IFooterMenuBrandItemModel,
    IFooterMenuProductItemModel,
} from "../../models/footer-menu-models";
import { MiscApi } from "../../AAL/misc-api";

export default function Footer() {
    const [brandMenus, setBrandMenus] = useState<IFooterMenuBrandItemModel[]>(
        []
    );
    const [productMenus, setProductMenus] = useState<
        IFooterMenuProductItemModel[]
    >([]);
    const [facebookUrl, setFacebookUrl] = useState<string>("");
    const [instagramUrl, setInstagramUrl] = useState<string>("");
    const [whatsAppNumber, setWhatsAppNumber] = useState<string>("");
    const navigate = useNavigate();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const result = await Promise.all([
                MiscApi.getBrandFooterMenuList(),
                MiscApi.getProductFooterMenuList(),
                MiscApi.getSocialLinks(),
            ]);
            setBrandMenus(result[0]);
            setProductMenus(result[1]);
            if (result[2]) {
                setFacebookUrl(result[2].facebook ?? "");
                setInstagramUrl(result[2].instagram ?? "");
                setWhatsAppNumber(result[2].whatsAppNumber ?? "");
            }
        } catch (error) {}
    };
    return (
        <div className="mt-5">
            <div className="footer-container">
                <Container>
                    <Row>
                        <Col lg={6}>
                            <Row>
                                <Col>
                                    <ul className="list-unstyled  footer-list">
                                        <li className="h5 pb-3">Merken</li>
                                        {brandMenus
                                            ?.sort((a, b) => a.order - b.order)
                                            .map((m) => (
                                                <li key={m.id} className="mb-2">
                                                    <NavLink
                                                        className="text-black"
                                                        to={
                                                            "/models/" +
                                                            m.brand.slug
                                                        }
                                                    >
                                                        {m.brand.name}
                                                    </NavLink>
                                                </li>
                                            ))}

                                        <li>
                                            <NavLink to={"/brands"}>
                                                Alle merken
                                            </NavLink>
                                        </li>
                                    </ul>
                                </Col>

                                <Col>
                                    <ul className="list-unstyled footer-list">
                                        <li className="h5 pb-3">Reparaties</li>
                                        {productMenus
                                            ?.sort((a, b) => a.order - b.order)
                                            .map((m) => (
                                                <li className="mb-2" key={m.id}>
                                                    <NavLink
                                                        className="text-black"
                                                        to={
                                                            "/reparatie/" +
                                                            m.product.slug
                                                        }
                                                    >
                                                        {m.product.name}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        <li>
                                            <NavLink to={"/brands"}>
                                                Alle reparaties
                                            </NavLink>
                                        </li>
                                    </ul>
                                </Col>

                                <Col>
                                    <ul className="list-unstyled footer-list">
                                        <li className="h5 pb-3">Informatie</li>

                                        <li className="mb-2">
                                            <NavLink
                                                className="text-black"
                                                to={"/over-ons"}
                                            >
                                                Over ons
                                            </NavLink>
                                        </li>
                                        <li className="mb-2">
                                            <NavLink
                                                className="text-black"
                                                to={"/over-ons"}
                                            >
                                                Onze dienstverlening
                                            </NavLink>
                                        </li>
                                        <li className="mb-2">
                                            <NavLink
                                                className="text-black"
                                                to={"/contact"}
                                            >
                                                Contact
                                            </NavLink>
                                        </li>
                                        <li className="mb-2">
                                            <NavLink
                                                className="text-black"
                                                to={"/zakelijk"}
                                            >
                                                Zakelijk
                                            </NavLink>
                                        </li>
                                        <li className="mb-2">
                                            <NavLink
                                                className="text-black"
                                                to={"/faq"}
                                            >
                                                Veelgestelde vragen
                                            </NavLink>
                                        </li>
                                        <li className="mb-2">
                                            <NavLink
                                                className="text-black"
                                                to={"/blog"}
                                            >
                                                Blog
                                            </NavLink>
                                        </li>
                                        <li className="mb-2">
                                            <NavLink
                                                className="text-black"
                                                to={"/regio"}
                                            >
                                                Regio
                                            </NavLink>
                                        </li>
                                        <li className="mb-2">
                                            <NavLink
                                                className="text-black"
                                                to={"/disclaimer"}
                                            >
                                                Disclaimer
                                            </NavLink>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={6}>
                            <Row>
                                <Col
                                    lg={{
                                        offset: 1,
                                        span: 7,
                                    }}
                                >
                                    <ul className="list-unstyled footer-list">
                                        <li className="h5 pb-3">Adres</li>
                                        <li className="mb-2">4Mobiles:</li>
                                        <li className="mb-2">Molenstraat 2</li>
                                        <li className="mb-2">
                                            2671 EX Naaldwijk
                                        </li>
                                    </ul>
                                </Col>
                                <Col lg={4}>
                                    <h5 className="h5 mb-3">Openingstijden</h5>
                                    <Row>
                                        <Col xs={6} lg={12}>
                                            <p className="text-bold">
                                                MA 13.00u-17.30u
                                            </p>
                                            <p className="text-bold">
                                                DI 9.30u-17.30u
                                            </p>
                                            <p className="text-bold">
                                                WO 9.30u-17.30u
                                            </p>
                                            <p className="text-bold">
                                                DO 9.30u-17.30u
                                            </p>
                                        </Col>
                                        <Col xs={6} lg={12}>
                                            <p className="text-bold">
                                                Vr 9.30u-20.00u
                                            </p>
                                            <p className="text-bold">
                                                ZA 9.30u-17.00u
                                            </p>

                                            <p className="text-bold">
                                                ZO 12.00u-17.00u*
                                            </p>
                                            <p className="text-bold">
                                                *laatste zondag van de maand
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div>
                <div className="d-none mt-4 d-lg-block small overflow-hidden footer-line">
                    <Row>
                        <Col>
                            4Mobiles © {new Date().getFullYear()} All Rights
                            Reserved
                        </Col>
                        <Col className="text-center">
                            <NavLink
                                className="text-black m-3"
                                to={"/privacy-policy"}
                            >
                                Privacy Policy
                            </NavLink>{" "}
                            |
                            <NavLink
                                className="text-black m-3"
                                to={"/algemene-voorwaarden"}
                            >
                                {" "}
                                Algemene voorwaarden
                            </NavLink>
                        </Col>
                        <Col>
                            <div
                                style={{
                                    textAlign: "right",
                                }}
                            >
                                <a
                                    target="_blank"
                                    href={`https://wa.me/${whatsAppNumber}`}
                                >
                                    <svg
                                        className="m-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="25"
                                        viewBox="0 0 24 25"
                                        fill="none"
                                    >
                                        <path
                                            d="M12 5.47578C8.10536 5.47578 4.93929 8.59238 4.93393 12.4262C4.93393 13.7393 5.30893 15.0207 6.01607 16.1229L6.18214 16.3865L5.46964 18.9494L8.14286 18.2586L8.4 18.4115C9.48214 19.0443 10.725 19.3818 11.9946 19.3818H12C15.8893 19.3818 19.1411 16.2652 19.1411 12.4314C19.1411 10.5752 18.3268 8.82969 16.9929 7.5166C15.6536 6.19824 13.8857 5.47578 12 5.47578ZM16.1518 15.4109C15.975 15.9014 15.1286 16.3443 14.7214 16.4023C14.0464 16.5025 13.5214 16.4498 12.1768 15.8803C10.05 14.9732 8.65714 12.8639 8.55 12.7268C8.44286 12.5896 7.68214 11.593 7.68214 10.5646C7.68214 9.53633 8.22857 9.03008 8.42679 8.81914C8.61964 8.6082 8.85 8.55547 8.99464 8.55547C9.13393 8.55547 9.27857 8.55547 9.40179 8.56074C9.53036 8.56602 9.70714 8.51328 9.87857 8.91934C10.0554 9.33594 10.4786 10.3643 10.5321 10.4697C10.5857 10.5752 10.6232 10.6965 10.5482 10.8336C10.1411 11.6352 9.70714 11.6035 9.92679 11.9727C10.7464 13.3596 11.5661 13.8395 12.8143 14.4564C13.0286 14.5619 13.1518 14.5461 13.275 14.4037C13.3982 14.2666 13.8054 13.792 13.9446 13.5863C14.0839 13.3754 14.2286 13.4123 14.4214 13.4809C14.6143 13.5494 15.6589 14.0557 15.8732 14.1611C16.0875 14.2666 16.2268 14.3193 16.2804 14.4037C16.3286 14.5039 16.3286 14.9258 16.1518 15.4109ZM21.4286 0.6875H2.57143C1.15179 0.6875 0 1.82129 0 3.21875V21.7812C0 23.1787 1.15179 24.3125 2.57143 24.3125H21.4286C22.8482 24.3125 24 23.1787 24 21.7812V3.21875C24 1.82129 22.8482 0.6875 21.4286 0.6875ZM11.9946 20.7898C10.5696 20.7898 9.17143 20.4365 7.93393 19.7721L3.42857 20.9375L4.63393 16.6027C3.88929 15.3371 3.49821 13.8975 3.49821 12.4209C3.50357 7.81191 7.3125 4.0625 11.9946 4.0625C14.2661 4.0625 16.3982 4.93262 18.0054 6.51465C19.6071 8.09668 20.5714 10.1955 20.5714 12.4314C20.5714 17.0404 16.6768 20.7898 11.9946 20.7898Z"
                                            fill="#81BC28"
                                        />
                                    </svg>
                                </a>

                                <a target="_blank" href={facebookUrl}>
                                    <svg
                                        className="m-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="26"
                                        height="25"
                                        viewBox="0 0 36 35"
                                        fill="none"
                                    >
                                        <path
                                            d="M31.3004 0.85H4.70039C2.61039 0.85 0.900391 2.515 0.900391 4.55V30.45C0.900391 32.4868 2.61039 34.15 4.70039 34.15H18.0004V21.2H14.2004V16.6212H18.0004V12.8287C18.0004 8.82535 20.3032 6.01335 25.1558 6.01335L28.5815 6.01705V10.8363H26.3072C24.4186 10.8363 23.7004 12.2164 23.7004 13.4966V16.6231H28.5796L27.5004 21.2H23.7004V34.15H31.3004C33.3904 34.15 35.1004 32.4868 35.1004 30.45V4.55C35.1004 2.515 33.3904 0.85 31.3004 0.85Z"
                                            fill="black"
                                        />
                                    </svg>
                                </a>

                                <a target="_blank" href={instagramUrl}>
                                    <svg
                                        className="m-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="26"
                                        viewBox="0 0 25 26"
                                        fill="none"
                                    >
                                        <rect
                                            width="24.5399"
                                            height="25.5208"
                                            rx="3"
                                            fill="#EEF8EA"
                                        />
                                        <g clipPath="url(#clip0_919_2759)">
                                            <path
                                                d="M15.3125 5H9.6875C7.09912 5 5 7.09912 5 9.6875V15.3125C5 17.9009 7.09912 20 9.6875 20H15.3125C17.9009 20 20 17.9009 20 15.3125V9.6875C20 7.09912 17.9009 5 15.3125 5ZM18.5938 15.3125C18.5938 17.1219 17.1219 18.5938 15.3125 18.5938H9.6875C7.87812 18.5938 6.40625 17.1219 6.40625 15.3125V9.6875C6.40625 7.87812 7.87812 6.40625 9.6875 6.40625H15.3125C17.1219 6.40625 18.5938 7.87812 18.5938 9.6875V15.3125Z"
                                                fill="#1A1818"
                                            />
                                            <path
                                                d="M12.5 8.75C10.429 8.75 8.75 10.429 8.75 12.5C8.75 14.571 10.429 16.25 12.5 16.25C14.571 16.25 16.25 14.571 16.25 12.5C16.25 10.429 14.571 8.75 12.5 8.75ZM12.5 14.8438C11.208 14.8438 10.1562 13.792 10.1562 12.5C10.1562 11.2071 11.208 10.1562 12.5 10.1562C13.792 10.1562 14.8438 11.2071 14.8438 12.5C14.8438 13.792 13.792 14.8438 12.5 14.8438Z"
                                                fill="#1A1818"
                                            />
                                            <path
                                                d="M16.5312 8.96836C16.8073 8.96836 17.031 8.74459 17.031 8.46855C17.031 8.19252 16.8073 7.96875 16.5312 7.96875C16.2552 7.96875 16.0314 8.19252 16.0314 8.46855C16.0314 8.74459 16.2552 8.96836 16.5312 8.96836Z"
                                                fill="#1A1818"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_919_2759">
                                                <rect
                                                    width="15"
                                                    height="15"
                                                    fill="white"
                                                    transform="translate(5 5)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="d-lg-none text-center mt-4">
                    <p className="small mb-0 text-primary">
                        4Mobiles © 2022 All Rights Reserved
                    </p>
                    <p className="text-primary mb-0">
                        <NavLink
                            className="text-black m-1 small"
                            to={"/privacy-policy"}
                        >
                            Privacy Policy
                        </NavLink>{" "}
                        |
                        <NavLink
                            className="text-black m-1 small"
                            to={"/algemene-voorwaarden"}
                        >
                            {" "}
                            Algemene voorwaarden
                        </NavLink>
                    </p>
                    <hr className="mb-0 mt-2" />
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <a
                            target="_blank"
                            href={`https://wa.me/${whatsAppNumber}`}
                        >
                            <svg
                                className="m-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="25"
                                viewBox="0 0 24 25"
                                fill="none"
                            >
                                <path
                                    d="M12 5.47578C8.10536 5.47578 4.93929 8.59238 4.93393 12.4262C4.93393 13.7393 5.30893 15.0207 6.01607 16.1229L6.18214 16.3865L5.46964 18.9494L8.14286 18.2586L8.4 18.4115C9.48214 19.0443 10.725 19.3818 11.9946 19.3818H12C15.8893 19.3818 19.1411 16.2652 19.1411 12.4314C19.1411 10.5752 18.3268 8.82969 16.9929 7.5166C15.6536 6.19824 13.8857 5.47578 12 5.47578ZM16.1518 15.4109C15.975 15.9014 15.1286 16.3443 14.7214 16.4023C14.0464 16.5025 13.5214 16.4498 12.1768 15.8803C10.05 14.9732 8.65714 12.8639 8.55 12.7268C8.44286 12.5896 7.68214 11.593 7.68214 10.5646C7.68214 9.53633 8.22857 9.03008 8.42679 8.81914C8.61964 8.6082 8.85 8.55547 8.99464 8.55547C9.13393 8.55547 9.27857 8.55547 9.40179 8.56074C9.53036 8.56602 9.70714 8.51328 9.87857 8.91934C10.0554 9.33594 10.4786 10.3643 10.5321 10.4697C10.5857 10.5752 10.6232 10.6965 10.5482 10.8336C10.1411 11.6352 9.70714 11.6035 9.92679 11.9727C10.7464 13.3596 11.5661 13.8395 12.8143 14.4564C13.0286 14.5619 13.1518 14.5461 13.275 14.4037C13.3982 14.2666 13.8054 13.792 13.9446 13.5863C14.0839 13.3754 14.2286 13.4123 14.4214 13.4809C14.6143 13.5494 15.6589 14.0557 15.8732 14.1611C16.0875 14.2666 16.2268 14.3193 16.2804 14.4037C16.3286 14.5039 16.3286 14.9258 16.1518 15.4109ZM21.4286 0.6875H2.57143C1.15179 0.6875 0 1.82129 0 3.21875V21.7812C0 23.1787 1.15179 24.3125 2.57143 24.3125H21.4286C22.8482 24.3125 24 23.1787 24 21.7812V3.21875C24 1.82129 22.8482 0.6875 21.4286 0.6875ZM11.9946 20.7898C10.5696 20.7898 9.17143 20.4365 7.93393 19.7721L3.42857 20.9375L4.63393 16.6027C3.88929 15.3371 3.49821 13.8975 3.49821 12.4209C3.50357 7.81191 7.3125 4.0625 11.9946 4.0625C14.2661 4.0625 16.3982 4.93262 18.0054 6.51465C19.6071 8.09668 20.5714 10.1955 20.5714 12.4314C20.5714 17.0404 16.6768 20.7898 11.9946 20.7898Z"
                                    fill="#81BC28"
                                />
                            </svg>
                        </a>

                        <a target="_blank" href={facebookUrl}>
                            <svg
                                className="m-3"
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="25"
                                viewBox="0 0 35 36"
                                fill="none"
                            >
                                <path
                                    d="M31.3004 0.85H4.70039C2.61039 0.85 0.900391 2.515 0.900391 4.55V30.45C0.900391 32.4868 2.61039 34.15 4.70039 34.15H18.0004V21.2H14.2004V16.6212H18.0004V12.8287C18.0004 8.82535 20.3032 6.01335 25.1558 6.01335L28.5815 6.01705V10.8363H26.3072C24.4186 10.8363 23.7004 12.2164 23.7004 13.4966V16.6231H28.5796L27.5004 21.2H23.7004V34.15H31.3004C33.3904 34.15 35.1004 32.4868 35.1004 30.45V4.55C35.1004 2.515 33.3904 0.85 31.3004 0.85Z"
                                    fill="black"
                                />
                            </svg>
                        </a>
                        <a target="_blank" href={instagramUrl}>
                            <svg
                                className="m-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="26"
                                viewBox="0 0 25 26"
                                fill="none"
                            >
                                <rect
                                    width="24.5399"
                                    height="25.5208"
                                    rx="3"
                                    fill="#EEF8EA"
                                />
                                <g clipPath="url(#clip0_919_2759)">
                                    <path
                                        d="M15.3125 5H9.6875C7.09912 5 5 7.09912 5 9.6875V15.3125C5 17.9009 7.09912 20 9.6875 20H15.3125C17.9009 20 20 17.9009 20 15.3125V9.6875C20 7.09912 17.9009 5 15.3125 5ZM18.5938 15.3125C18.5938 17.1219 17.1219 18.5938 15.3125 18.5938H9.6875C7.87812 18.5938 6.40625 17.1219 6.40625 15.3125V9.6875C6.40625 7.87812 7.87812 6.40625 9.6875 6.40625H15.3125C17.1219 6.40625 18.5938 7.87812 18.5938 9.6875V15.3125Z"
                                        fill="#1A1818"
                                    />
                                    <path
                                        d="M12.5 8.75C10.429 8.75 8.75 10.429 8.75 12.5C8.75 14.571 10.429 16.25 12.5 16.25C14.571 16.25 16.25 14.571 16.25 12.5C16.25 10.429 14.571 8.75 12.5 8.75ZM12.5 14.8438C11.208 14.8438 10.1562 13.792 10.1562 12.5C10.1562 11.2071 11.208 10.1562 12.5 10.1562C13.792 10.1562 14.8438 11.2071 14.8438 12.5C14.8438 13.792 13.792 14.8438 12.5 14.8438Z"
                                        fill="#1A1818"
                                    />
                                    <path
                                        d="M16.5312 8.96836C16.8073 8.96836 17.031 8.74459 17.031 8.46855C17.031 8.19252 16.8073 7.96875 16.5312 7.96875C16.2552 7.96875 16.0314 8.19252 16.0314 8.46855C16.0314 8.74459 16.2552 8.96836 16.5312 8.96836Z"
                                        fill="#1A1818"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_919_2759">
                                        <rect
                                            width="15"
                                            height="15"
                                            fill="white"
                                            transform="translate(5 5)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
