/* eslint-disable react/jsx-no-target-blank */
import Container from "react-bootstrap/Container";
import { HomePageHeader } from "../../components/home-page-header";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./styles.scss";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Info1, Info2, Info4 } from "../../images/images";
import { Link, NavLink, useParams } from "react-router-dom";
import { IFrequentSliderItemModel } from "../../models/frequent-slider-item";
import { MiscApi } from "../../AAL/misc-api";
import AppImage from "../../components/image/img";
import { IDeviceTypeModel } from "../../models/device-type-models";
import { DeviceTypeApi } from "../../AAL/device-type-api";
import { IPageContent } from "../../models/shared-models";
import { Helmet } from "react-helmet";
import Reviews from "../../components/reviews";
import HowItWorks from "../../components/how-it-works";
import { IFAQModel } from "../../models/faq-models";
import { FAQApi } from "../../AAL/faq-api";
import Accordion from "react-bootstrap/esm/Accordion";
import { IRegionalHomePageModel } from "../../models/regional-page-models";
import { RegionalHomePagesApi } from "../../AAL/regional-pages-api";
import Why4Mobile from "../../components/why-4mobiles";

export default function HomePage() {
	const { area } = useParams();
	const sliderRef = useRef<OwlCarousel>(null);
	const [seoContent, setSeoContent] = useState<IPageContent>();
	const [sliderItems, setSliderItems] = useState<IFrequentSliderItemModel[]>(
		[]
	);
	const [deviceTypes, setDeviceTypes] = useState<IDeviceTypeModel[]>([]);
	const [faqs, setFaqs] = useState<IFAQModel[]>([]);

	const [regionPage, setRegionPage] = useState<IRegionalHomePageModel>();

	useEffect(() => {
		if (area) {
			RegionalHomePagesApi.getBySlug(area).then((p) => {
				setRegionPage(p);
				setSeoContent({
					title: p.pageTitle,
					description: p.metaDescription,
					keywords: p.metaKeywords,
					content: p.content,
				});
			});
		} else {
			MiscApi.getHomePageContent()
				.then((d) => setSeoContent(d))
				.catch();
		}
	}, [area]);

	useEffect(() => {
		loadData();
		function onRes(e: UIEvent) {
			const btc1 = document.getElementById("btc1")?.clientHeight;
			const btc2 = document.getElementById("btc2");
			const btc3 = document.getElementById("btc3");
			if (btc2 && btc1) {
				btc2.style.height = btc1.toString() + "px";
			}

			if (btc3 && btc1) {
				btc3.style.height = btc1.toString() + "px";
			}
		}
		window.addEventListener("resize", onRes);
		window.addEventListener("load", () => {
			const btc1 = document.getElementById("btc1")?.clientHeight;
			const btc2 = document.getElementById("btc2");
			const btc3 = document.getElementById("btc3");
			if (btc2 && btc1) {
				btc2.style.height = btc1.toString() + "px";
			}

			if (btc3 && btc1) {
				btc3.style.height = btc1.toString() + "px";
			}
		});

		return () => {
			window.removeEventListener("resize", onRes);
		};
	}, []);

	const loadData = async () => {
		try {
			const data = await Promise.all([
				MiscApi.getFrequentSliderItems(),
				DeviceTypeApi.getAll(),
				FAQApi.getAll(),
			]);
			setSliderItems(data[0]);
			setDeviceTypes(data[1]);
			setFaqs(data[2]);
		} catch (error) {}
	};

	return (
		<>
			{seoContent ? (
				<Helmet>
					<title>{seoContent?.title}</title>
					<meta name="description" content={seoContent.description} />
					<meta name="keywords" content={seoContent.keywords} />
				</Helmet>
			) : undefined}
			{regionPage?.pageHeader ? (
				<h3 className="text-center mt-3">{regionPage.pageHeader}</h3>
			) : undefined}
			<h2>{}</h2>
			<HomePageHeader />

			<Reviews />

			{/** Device Types */}
			{deviceTypes?.length ? (
				<div
					id="deviceTypeOptions"
					className="device-type-container py-5 "
				>
					<Container>
						<p className="device-type-title text-center mb-4">
							Hoeveel kost een reparatie van een telefoon?
						</p>
						<Row className="d-flex justify-content-center mb-4">
							{deviceTypes
								?.sort((a, b) => a.order - b.order)
								?.map((type) => (
									<Col xs={6} md={4} lg={3} key={type.id}>
										<Card className="device-type-card h-100">
											<Card.Body className="d-flex flex-column text-center justify-content-evenly">
												<AppImage
													className="w-100 non-selectable"
													name={type.thumbnail}
												/>
												<p className="text-center device-type-card-text">
													{type.name}
												</p>
												<div>
													<Link
														className="btn btn-sm btn-primary"
														to={
															"/" +
															encodeURIComponent(
																type.slug?.replaceAll(
																	" ",
																	"-"
																)
															) +
															"/brands"
														}
													>
														Bekijk Prijzen
													</Link>
												</div>
											</Card.Body>
										</Card>
									</Col>
								))}
						</Row>
					</Container>
				</div>
			) : undefined}

			{/** Slider */}
			{sliderItems?.length ? (
				<div className="slider-container">
					<Container>
						<p className="device-type-title text-center mt-3 mb-5 text-white">
							Veel voorkomende reparaties
						</p>
						<div className="row">
							<div className="col-1  d-flex justify-content-center">
								<button
									className="slider-btn ms-1"
									onClick={() => {
										sliderRef?.current?.prev(500);
									}}
								>
									<FontAwesomeIcon icon={faAngleLeft} />
								</button>
							</div>

							<div className="col-10">
								<OwlCarousel
									ref={sliderRef}
									responsive={{
										0: {
											items: 2,
										},
										576: {
											items: 3,
										},
										768: {
											items: 4,
										},
										900: {
											items: 6,
										},
									}}
									className="owl-theme"
									margin={15}
								>
									{sliderItems?.map((item, i) => (
										<SliderItem item={item} key={i} />
									))}
								</OwlCarousel>
							</div>

							<div className="col-1 d-flex justify-content-center">
								<button
									className="slider-btn me-1"
									onClick={() => {
										sliderRef?.current?.next(500);
									}}
								>
									<FontAwesomeIcon icon={faAngleRight} />
								</button>
							</div>
						</div>
						<div className="text-center">
							<NavLink
								className="btn btn-outline-primary mb-3 mt-5 text-white"
								to={"/brands"}
							>
								Alle Reparaties
							</NavLink>
						</div>
					</Container>
				</div>
			) : undefined}

			{/** How it works */}
			<div
				style={{
					backgroundColor: "rgba(215, 215, 215, 0.21)",
				}}
				className="py-5 border-bottom border-5 border-primary"
			>
				<Container className="pb-5">
					<HowItWorks />
				</Container>
			</div>

			{/** Info*/}
			<div
				className="info-section border-top border-dark border-5"
				style={{
					borderColor: "black",
					marginTop: 1,
				}}
			>
				<Container>
					<Row>
						<Col lg={8}>
							<div className="h-100 d-flex flex-column">
								<p className="device-type-title text-center my-4 mb-5 py-0 py-lg-3 py-xl-0">
									Onze diensten
								</p>
								<Row className="flex-fill">
									<Col sm={6}>
										<Card className="shadow h-100">
											<Card.Img src={Info1} />
											<Card.Body>
												<div className="d-flex flex-column justify-content-between h-100">
													<div className="">
														<h4 className="text-bold">
															Verkoop
														</h4>
														<p>
															Een ruim assortiment
															aan hoesjes en
															accessoires voor u
															telefoon of iPad,
															onder andere;
														</p>
														<ul className="list-with-primary-bullets">
															<li>Hoesjes</li>
															<li>
																Screenprotectors
															</li>
															<li>Opladers</li>
															<li>Kabels</li>
															<li>
																Auto houders
															</li>
														</ul>
													</div>

													<div id="btc3">
														<Link
															to={"/over-ons"}
															className="btn btn-primary rounded-3"
														>
															Meer info
														</Link>
													</div>
												</div>
											</Card.Body>
										</Card>
									</Col>
									<Col sm={6}>
										<Card className="shadow h-100 mt-4 mt-sm-0">
											<Card.Img src={Info2} />
											<Card.Body>
												<div className="d-flex flex-column justify-content-between h-100">
													<div>
														<h4 className="text-bold">
															Reparaties
														</h4>
														<p>
															Wij repareren
															smartphones en
															tablets op locatie.
															U kunt bij ons
															terecht voor onder
															andere;
														</p>
														<ul className="list-with-primary-bullets">
															<li>
																Scherm reparatie
															</li>
															<li>Waterschade</li>
															<li>Batterij</li>
															<li>Offertes</li>
															<li>Advies</li>
														</ul>
													</div>
													<div id="btc2">
														<Link
															to={"/over-ons"}
															className="btn btn-primary rounded-3"
														>
															Meer info
														</Link>
													</div>
												</div>
											</Card.Body>
										</Card>
									</Col>
								</Row>
							</div>
						</Col>
						<Col lg={4}>
							<Why4Mobile />
						</Col>
					</Row>
				</Container>
			</div>

			{/** Info 2 */}
			<div className="info-section-alt mt-4">
				<Container>
					<Row>
						<Col lg={6}>
							<div className=" text-center text-lg-start">
								<h2 className="mt-3 mb-4 mb-lg-3 info-section4-heading">
									Wij repareren ook Zakelijk!
								</h2>
								<p className="">
									Steeds meer bedrijven voorzien personeel van
									een smartphone of tablet van de zaak. We
									zijn steeds meer thuis en onderweg gaan
									werken, waardoor bedrijven er veel voordeel
									van hebben als medewerkers gebruik kunnen
									maken van een goede smartphone of tablet.
									Wanneer u als bedrijf smartphones en / of
									tablets heeft aangeschaft voor uw personeel,
									dan bent u bij 4Mobiles in Naaldwijk aan het
									juiste adres voor hoesjes, accessoires en
									professionele reparatieservices.
								</p>

								<Link to={"/zakelijk"} className="btn">
									Meer informatie
								</Link>
							</div>
						</Col>

						<Col lg={6}>
							<img
								src={Info4}
								className="w-100 mt-5 mt-lg-0 non-selectable"
								alt=""
							/>
						</Col>
					</Row>
				</Container>
			</div>

			{/**FAQ */}
			<div className="info-section">
				<Container>
					<h3 className="text-bold text-center my-4">
						Veelgestelde vragen
					</h3>
					<Row className="g-5 home-faq">
						{faqs
							?.filter((x) => x.showOnHomePage)
							?.map((faq, i) => (
								<Col md={6} key={i}>
									<Accordion className="shadow" flush>
										<Accordion.Item
											className=" faq-accordian-bg"
											eventKey={faq.id.toString()}
										>
											<Accordion.Header className="faq-accordian-bg">
												<p
													className="m-0"
													style={{
														height: 20,
														textOverflow:
															"ellipsis",
														overflow: "hidden",
														whiteSpace: "nowrap",
													}}
												>
													{faq.question}
												</p>
											</Accordion.Header>
											<Accordion.Body>
												{faq.answer}
												{faq.readMoreLink ? (
													<a
														rel="noreferrer"
														target="_blank"
														href={faq.readMoreLink}
													>
														{" "}
														meer info
													</a>
												) : undefined}
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</Col>
							))}
					</Row>
					<Row className="g-5 home-faq">
						<Col md={6}>
							<div className="text-end mt-4">
								<Link className="text-black" to="/faq">
									Bekijk alle vragen
								</Link>
							</div>
						</Col>
					</Row>
				</Container>
			</div>

			{seoContent?.content ? (
				<Container>
					<div
						className="mt-5 p-0 ql-editor"
						dangerouslySetInnerHTML={{
							__html: seoContent?.content ?? "",
						}}
					></div>
				</Container>
			) : undefined}
		</>
	);
}
type TSliderItemProps = {
	item: IFrequentSliderItemModel;
};
function SliderItem(props: TSliderItemProps) {
	return (
		<div
			className="slider-item d-flex flex-column h-100"
			style={{
				cursor: "pointer",
			}}
		>
			<NavLink
				to={
					"/reparatie/" +
					encodeURIComponent(
						props.item.product.slug.replaceAll(" ", "-")
					)
				}
			>
				<AppImage
					className="slider-item-img flex-fill"
					name={props.item.product.thumbnail}
				/>
				<p className="text-center mt-3 slider-item-text">
					{props.item.product.name}
				</p>
			</NavLink>
		</div>
	);
}
