import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, useEffect, useState } from "react";
import Form from "react-bootstrap/esm/Form";
import { EnquiryApi } from "../../AAL/enquiry-api";
import { HandleError } from "../../errors/handler";

export default function EnquiryForm() {
	const [error, setError] = useState<string>("");
	const [submited, setSubmited] = useState<boolean>(false);
	const [submitingEnquiry, setSubmitingEnquiry] = useState<boolean>(false);

	//form states
	const [name, setName] = useState<string>("");
	const [phoneNumber, setPhoneNumber] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [note, setNote] = useState<string>("");
	const [checked, setChecked] = useState<boolean>(false);

	useEffect(() => {
		if (submited) {
			setTimeout(() => {
				setSubmited(false);
			}, 5000);
		}
	}, [submited]);

	const submitEnquiry = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		e.stopPropagation();
		try {
			setError("");
			if (!email || !name || !note) {
				setError("Please fill all fields.");
				return;
			}
			if (!checked) {
				setError("Please accept terms and conditions.");
				return;
			}
			setSubmitingEnquiry(true);
			await EnquiryApi.makeEnquiry({
				name,
				phoneNumber,
				email,
				note,
			});
			setSubmited(true);
			setName("");
			setEmail("");
			setPhoneNumber("");
			setNote("");
			e.currentTarget?.reset();
		} catch (error) {
			setError(HandleError(error, false));
		} finally {
			setSubmitingEnquiry(false);
		}
	};

	return (
		<Form onSubmit={submitEnquiry}>
			<h4>Contactformulier</h4>
			{submited ? (
				<div className="alert alert-success">
					<p>
						Bedankt! Aanvraag succesvol ingediend. we nemen snel
						contact met je op.
					</p>
				</div>
			) : undefined}
			<Form.Group className="mb-2">
				<Form.Label>Naam</Form.Label>
				<Form.Control
					value={name}
					required
					onChange={(e) => setName(e.target.value)}
					type="text"
				/>
			</Form.Group>

			<Form.Group className="mb-2">
				<Form.Label>Telefoonnummer</Form.Label>
				<Form.Control
					value={phoneNumber}
					onChange={(e) => setPhoneNumber(e.target.value)}
					type="text"
				/>
			</Form.Group>

			<Form.Group className="mb-2">
				<Form.Label>Email</Form.Label>
				<Form.Control
					value={email}
					required
					onChange={(e) => setEmail(e.target.value)}
					type="email"
				/>
			</Form.Group>

			<Form.Group className="mb-2">
				<Form.Label>Bericht</Form.Label>
				<Form.Control
					value={note}
					onChange={(e) => setNote(e.target.value)}
					as="textarea"
					required
					className="h-100"
					rows={3}
				/>
			</Form.Group>
			<Form.Group className="mb-2">
				<Form.Check
					checked={checked}
					onChange={(e) => setChecked(!checked)}
					style={{
						fontSize: "small",
					}}
					label="Ik heb de algemene voorwaarden gelezen en ga hiermee akkoord"
				/>
			</Form.Group>
			{error ? (
				<div className="my-3 text-danger">{error}</div>
			) : undefined}

			<div className="text-center mt-3">
				<button
					disabled={submitingEnquiry}
					className="btn bg-white text-primary text-bold"
					type="submit"
				>
					{submitingEnquiry ? (
						"Please wait..."
					) : (
						<>
							Verstuur{" "}
							<FontAwesomeIcon
								icon={faArrowAltCircleRight}
								size="xl"
							/>
						</>
					)}
				</button>
			</div>
		</Form>
	);
}
