export enum OrderStatus {
    Placed = 0,
    Canceled = 1,
    Confirmed = 2,
    Rejected = 3,
    Received = 4,
    Repairing = 5,
    Repaired = 6,
    Invoiced = 7,
    Delivered = 8
}