import { appAxios } from "../configs/api-config";
import { OrderStatus } from "../enums/order-status";
import { ICreateOrderModel, IOrderModel, IOrderStatusUpdateModel, IUpdateOrderModel, IUpdateOrderStatusModel, IUploadOrderInvoiceModel } from "../models/order-model";
import { BaseApi } from "./base-api";

export class OrderApi extends BaseApi {
    private static url = (api: string) => `order/${api}`;

    /**
     * create new 
     * @param CreateOrderModel
     * @returns Order
     */
    public static async create(order: ICreateOrderModel): Promise<IOrderModel> {
        return this._post(this.url('create'), order);
    }

    /**
     * update order
     * @param UpdateOrderModel
     * @returns OrderModel
     */
    public static async update(order: IUpdateOrderModel): Promise<IOrderModel> {
        return this._patch(this.url('update'), order);
    }

    /**
    * update order status
    * @param UpdateOrderStatusModel
    * @returns void
    */
    public static async updateStatus(data: IUpdateOrderStatusModel): Promise<IOrderStatusUpdateModel> {
        return this._patch(this.url('updateStatus'), data);
    }

    /**
     * Get Order
     * @param id Order  id
     * @returns Order Object
     */
    public static async get(id: number): Promise<IOrderModel> {
        return this._get(this.url('getadmin'), {
            id
        });
    }

    /**
     * Get Orders by status
     * @param status Order Status
     * @returns List of Orders
     */
    public static async getByStatus(status: OrderStatus): Promise<IOrderModel[]> {
        return this._get(this.url('getallbystatus'), {
            status
        });
    }

    /**
     * Get all Orders
     * @returns List of Orders
     */
    public static async getAll(): Promise<IOrderModel[]> {
        return this._get(this.url('GetAllByUser'));
    }

    /**
     * Delete Order
     * @param id Order Id
     * @returns void
     */
    public static async delete(id: number): Promise<void> {
        return this._delete(this.url("delete"), { id });
    }

    /**
     * Set Status to Active
     * @param id Order Id
     * @returns void
     */
    public static async setActive(id: number): Promise<void> {
        return this._patch(this.url("setactive"), {
            id
        });
    }

    /**
     * Set Status to InActive
     * @param id Order Id
     * @returns void
     */
    public static async setInActive(id: number): Promise<void> {
        return this._patch(this.url("setinactive"), {
            id
        });
    }

    /**
 * upload invoice
 * @param data
 * @returns file name
 */
    public static async uploadInvoice(data: IUploadOrderInvoiceModel): Promise<string> {
        let fd = new FormData();
        for (let key in data as any) {
            fd.append(key, (data as any)[key]);
        }
        return this._post(this.url('uploadinvoice'), fd);
    }

    /**
 * Delete Invoice
 * @param id Order Id
 * @returns void
 */
    public static async deleteInvoice(orderId: number): Promise<void> {
        return this._delete(this.url("deleteInvoice"), { orderId });
    }

    /**
     * Download Invoice 
     * @param orderId 
     * @returns 
     */
    public static async downloadInvoice(orderId: number): Promise<Blob> {
        return appAxios.get(this.url("ClientDownloadInvoice") + "?orderId=" + orderId, {
            responseType: 'arraybuffer'
        });
    }
}