import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { AboutUsImage } from "../../images/images";
import { NavLink } from "react-router-dom";
import { MiscApi } from "../../AAL/misc-api";
import Spinner from "react-bootstrap/Spinner";
import { IPageContent } from "../../models/shared-models";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import EnquiryForm from "../../components/enquiry-form";

export default function AboutUsPage() {
    const [seoContent, setSeoContent] = useState<IPageContent>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            setLoading(true);
            const data = await MiscApi.getAboutUsPageContent();
            setSeoContent(data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Container>
                {seoContent ? (
                    <Helmet>
                        <title>{seoContent?.title}</title>
                        <meta
                            name="description"
                            content={seoContent.description}
                        />
                        <meta name="keywords" content={seoContent.keywords} />
                    </Helmet>
                ) : undefined}
                {/**Breadcrumb */}
                <nav className="mt-2 mb-2">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <NavLink to="/" className="text-primary">
                                Home
                            </NavLink>
                        </li>
                        <li className="breadcrumb-item active">About Us</li>
                    </ol>
                </nav>

                <div>
                    {loading ? (
                        <div className="text-center my-5">
                            <Spinner variant="primary" className="my-5" />
                        </div>
                    ) : (
                        <div
                            className="mt-5 ql-editor"
                            dangerouslySetInnerHTML={{
                                __html: seoContent?.content ?? "",
                            }}
                        ></div>
                    )}

                    <div>
                        <Row>
                            <Col lg={6}>
                                <img
                                    className="d-none d-lg-block"
                                    src={AboutUsImage}
                                    style={{
                                        width: "50vw",
                                        marginLeft: "calc((50% - 50vw) / 2)",
                                    }}
                                    alt=""
                                />

                                <img
                                    className="d-lg-none"
                                    src={AboutUsImage}
                                    style={{
                                        width: "100vw",
                                        marginLeft: "calc((100% - 100vw) / 2)",
                                    }}
                                    alt=""
                                />
                            </Col>
                            <Col lg={6}>
                                <Card className="bg-primary border-0 m-lg-4">
                                    <Card.Body>
                                        <EnquiryForm />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        </>
    );
}
