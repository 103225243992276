import { IFAQModel } from "../models/faq-models";
import { BaseApi } from "./base-api";

export class FAQApi extends BaseApi {
    private static url = (api: string) => `faq/${api}`;

    /**
     * Get
     * @param id id
     * @returns  {IFAQModel}
     */
    public static async get(id: number): Promise<IFAQModel> {
        return this._get(this.url('get'), {
            id
        });
    }

    /**
     * Get all
     * @returns List of FAQ
     */
    public static async getAll(): Promise<IFAQModel[]> {
        return this._get(this.url('getall'));
    }
}