import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import SideNavMenuList from "../../components/side-menu-list";
import ImageItemCard from "../../components/image-item-card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowAltCircleRight,
	faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useParams } from "react-router-dom";
import StepProgress from "../../components/step-progress";
import { useEffect, useState } from "react";
import { IProductModel } from "../../models/product-models";
import { IBrandModel } from "../../models/brand-models";
import { BrandApi } from "../../AAL/brand-api";
import { ProductApi } from "../../AAL/product-api";
import PageLoading from "../../components/page-loading/page-loading";
import { MODELS_PER_PAGE } from "../../configs/constants";
import ReactPaginate from "react-paginate";
import { IPageContent } from "../../models/shared-models";
import { Helmet } from "react-helmet";
import { MiscApi } from "../../AAL/misc-api";

export default function ModelsPage() {
	const { brand, type } = useParams();
	const [brands, setBrands] = useState<IBrandModel[]>([]);
	const [products, setProducts] = useState<IProductModel[]>([]);
	const [loadingBrands, setLoadingBrands] = useState<boolean>(true);
	const [loadingProducts, setLoadingProducts] = useState<boolean>(true);
	const [searchString, setSearchString] = useState<string>("");
	const [seoContent, setSeoContent] = useState<IPageContent>();
	const [whatsAppNumber, setWhatsAppNumber] = useState<string>("");

	useEffect(() => {
		if (type) {
			BrandApi.getAllByDeviceTypeSlug(type)
				.then((b) => setBrands(b))
				.catch()
				.finally(() => setLoadingBrands(false));
		} else {
			BrandApi.getAll()
				.then((b) => setBrands(b))
				.catch()
				.finally(() => setLoadingBrands(false));
		}
	}, [brand, type]);

	useEffect(() => {
		if (brand) {
			ProductApi.getAllByBrandAndTypeSlug(brand, type ?? "")
				.then((prods) => {
					if (prods?.length) {
						setProducts(prods?.sort((a, b) => a.order - b.order));
					}
				})
				.catch()
				.finally(() => {
					setLoadingProducts(false);
				});
		}
	}, [brand, type]);

	useEffect(() => {
		if (brands?.length) {
			const b = brands.find((x) => x.slug === brand);
			if (b) {
				setSeoContent({
					title: b.pageTitle,
					description: b.metaDescription,
					keywords: b.metaKeyword,
				});
			}
		}
	}, [brand, brands]);

	useEffect(() => {
		MiscApi.getSocialLinks().then((res) =>
			setWhatsAppNumber(res.whatsAppNumber ?? "")
		);
	}, []);

	//pagination
	const filtered = products?.filter(
		(x) =>
			x.name.toLowerCase().includes(searchString?.toLowerCase() ?? "") ||
			x.model
				?.toLowerCase()
				.includes(searchString?.toLowerCase() ?? "") ||
			x.tags?.toLowerCase().includes(searchString?.toLowerCase() ?? "")
	);

	const [itemsOffset, setItemsOffset] = useState<number>(0);
	const pageCount = Math.ceil(filtered.length / MODELS_PER_PAGE);
	const endOffset = itemsOffset + MODELS_PER_PAGE;
	const currentPageItems = filtered.slice(itemsOffset, endOffset);

	const handlePageClick = (event: { selected: number }) => {
		const newOffset = (event.selected * MODELS_PER_PAGE) % filtered.length;
		setItemsOffset(newOffset);
		window.scrollTo(0, 0);
	};

	return (
		<Container className="brands-page-container">
			{seoContent ? (
				<Helmet>
					<title>{seoContent?.title}</title>
					<meta name="description" content={seoContent.description} />
					<meta name="keywords" content={seoContent.keywords} />
				</Helmet>
			) : (
				<Helmet>
					<title>Select Model</title>
					<meta name="description" content="" />
					<meta name="keywords" content="" />
				</Helmet>
			)}
			{/**Breadcrumb */}
			<nav className="mt-2 mb-2">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<NavLink to="/" className="text-primary">
							Home
						</NavLink>
					</li>
					<li className="breadcrumb-item">Model</li>
				</ol>
			</nav>
			{/** Step Progress */}
			<StepProgress currentStep={2} />

			<Row>
				<Col lg={2} md={3}></Col>
				<Col lg={10} md={9}>
					<div className="input-group mt-3">
						<input
							value={searchString}
							onChange={(e) => {
								setItemsOffset(0);
								setSearchString(e.target.value);
							}}
							placeholder="Select Phone Model of Number"
							type="text"
							className="form-control search-input"
						/>
						<button className="btn search-input-btn">
							<FontAwesomeIcon color="white" icon={faSearch} />
						</button>
					</div>
				</Col>
			</Row>

			{/** Content */}
			<Row className="mt-3">
				{/**Sidebar (menu) */}
				<Col className="d-none d-md-block" lg={2} md={3}>
					{loadingBrands ? (
						<PageLoading />
					) : (
						<SideNavMenuList
							title="Merk"
							list={brands?.slice(0, 10).map((t) => ({
								text: t.name,
								to: type
									? "/models/" +
									  type +
									  "/" +
									  encodeURIComponent(
											t.slug.replaceAll(" ", "-")
									  )
									: "/models/" +
									  encodeURIComponent(
											t.slug.replaceAll(" ", "-")
									  ),
								active: brand === t.slug,
							}))}
						/>
					)}
				</Col>
				{/**brands */}
				<Col sm={12} lg={10} md={9}>
					{loadingProducts ? (
						<PageLoading />
					) : currentPageItems?.length ? (
						<>
							<Row className="g-2 g-lg-4">
								{currentPageItems.map((i) => (
									<Col
										key={i.id}
										className="mb-2 mb-lg-4 text-center"
										xs={4}
										md={3}
										lg={2}
									>
										<ImageItemCard
											to={"/reparatie/" + i.slug}
											subText={i.model}
											text={i.name}
											imageName={i.thumbnail}
										/>
									</Col>
								))}
							</Row>

							<div className="d-flex justify-content-center mt-4 mb-4">
								<ReactPaginate
									breakLabel="..."
									nextLabel=">>"
									onPageChange={handlePageClick}
									pageRangeDisplayed={5}
									pageCount={pageCount}
									previousLabel="<<"
									containerClassName="pagination"
									pageClassName="page-item"
									pageLinkClassName="page-link"
									previousClassName="page-item"
									nextClassName="page-item"
									previousLinkClassName="page-link"
									nextLinkClassName="page-link"
									activeClassName="active"
								/>
							</div>
						</>
					) : (
						<p className="text-center my-5">
							No models found for selected brand...
						</p>
					)}

					<div className="repair-alert-box p-3">
						<p className="repair-alert-box-text m-0 text-center">
							Staat uw toestel er niet tussen? Neem dan contact
							met ons op. Wij staan voor u klaar!
						</p>
					</div>

					<div className="text-center mt-3">
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<a
							target="_blank"
							href={`https://wa.me/${whatsAppNumber}`}
							className="btn btn-primary px-5"
						>
							Contact
							<FontAwesomeIcon
								icon={faArrowAltCircleRight}
								size="xl"
								color="white"
								className="ms-3"
							/>
						</a>
					</div>
				</Col>
			</Row>
		</Container>
	);
}
