import axios from "axios";
import { AppError } from "../errors/app-error";
import { IUser } from "../models/user-model";
import { Routes } from "../router";
import { USR_LOCALSTORAGE_KEY } from "./constants";
export const BASEURL =
    process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_API_URL
        : "https://localhost:7152/api";
//export const BASEURL = process.env.REACT_APP_API_URL;

const appAxios = axios.create({
    baseURL: BASEURL,
});
appAxios.interceptors.request.use((conf: any) => {
    const str = localStorage.getItem(USR_LOCALSTORAGE_KEY);
    if (str) {
        const user: IUser = JSON.parse(str);
        conf.headers = {
            ...conf.headers,
            Authorization: `Bearer ${user.token}`,
        };
    }
    return conf;
});
appAxios.interceptors.response.use(
    (resp) => {
        if (resp.status === 401) {
            Routes.navigate("/login", {
                replace: true,
            });
        } else if (resp.status === 200) {
            if (
                resp.data &&
                typeof resp.data !== "string" &&
                resp.headers["content-type"] !== "application/pdf"
            ) {
                for (let key in resp.data) {
                    resp.data[key] = dateTransformer(resp.data[key]);
                }
            }
        }
        return resp;
    },
    (err) => {
        if (err?.response?.status === 401) {
            Routes.navigate("/login", {
                replace: true,
            });
            throw new AppError(
                401,
                "Your session has expired. Please login to continue..."
            );
        }
        throw err;
    }
);

function dateTransformer(data: any): any {
    if (Array.isArray(data)) {
        return data.map((val) => dateTransformer(val));
    }
    if (typeof data === "object" && data !== null) {
        return Object.fromEntries(
            Object.entries(data).map(([key, val]) => [
                key,
                dateTransformer(val),
            ])
        );
    }
    // eslint-disable-next-line no-useless-escape
    let reg = RegExp(
        `^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\\.[0-9]+)?(Z)?$`
    );

    if (reg.test(data)) {
        return new Date(data);
    }
    return data;
}

export const GetPhoto = (photo: string) => {
    return `${BASEURL}/photos/getphoto/${photo}`;
};

export { appAxios };
