import { Col, Container, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import Review from "./review";
import "./styles.scss";
import { useEffect, useRef, useState } from "react";
import { IGoogleReviewData } from "../../models/shared-models";
import { MiscApi } from "../../AAL/misc-api";

export default function Reviews() {
    const carouselRef = useRef<OwlCarousel>(null);
    const [reviewData, setReviewData] = useState<IGoogleReviewData>();
    useEffect(() => {
        MiscApi.getGoogleReviews().then((r) => setReviewData(r));
    }, []);

    return reviewData?.reviews?.length ? (
        <div className="review-container">
            <Container>
                <Row>
                    <Col md={6}>
                        <h3 className="text-bold">
                            {" "}
                            Onze klanten aan het woord.
                        </h3>
                        <p>
                            Wij zijn al 10 jaar gevestigd in het Westland en
                            hebben al meer dan 7500 reparaties uitgevoerd!
                        </p>
                    </Col>
                    <Col md={6}>
                        <div className="text-end mt-4">
                            <button
                                onClick={() => carouselRef.current?.prev(500)}
                                className="btn btn-sm me-2 slider-nav-btn"
                            >
                                Previous
                            </button>

                            <button
                                className="btn btn-sm slider-nav-btn"
                                onClick={() => carouselRef.current?.next(500)}
                            >
                                Next
                            </button>
                        </div>
                    </Col>
                </Row>
                <div className="mt-2">
                    <OwlCarousel
                        ref={carouselRef}
                        responsive={{
                            0: {
                                items: 1,
                            },
                            576: {
                                items: 2,
                            },
                            768: {
                                items: 3,
                            },
                            900: {
                                items: 4,
                            },
                        }}
                        className="owl-theme"
                        margin={15}
                        items={1.5}
                    >
                        {reviewData?.reviews?.map((rev, i) => (
                            <Review key={i} review={rev} />
                        ))}
                    </OwlCarousel>
                </div>

                <div className="text-center">
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.google.com/search?q=4mobiles&rlz=1C1ONGR_enIN1022IN1022&oq=4mobiles&aqs=chrome.0.69i59j69i60l4j5i44.1902j0j7&sourceid=chrome&ie=UTF-8#lrd=0x47c5b25e7bb1e8b7:0x2175e7ba4977687c,3,,,,"
                        className="btn btn-default  btn-sm my-3 btn-nav-from-review"
                    >
                        Bekijk alle reviews
                    </a>
                </div>
            </Container>
        </div>
    ) : (
        <></>
    );
}
