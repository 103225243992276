import ReactStars from "react-rating-stars-component";
import { ReactComponent as GoogleIcon } from "../../images/google-icon.svg";
import "./styles.scss";
import { useEffect, useState } from "react";
import { MiscApi } from "../../AAL/misc-api";

export default function GoogleReview() {
    const [rating, setRating] = useState<number>(0);
    useEffect(() => {
        MiscApi.getGoogleReviews().then((d) => setRating(d.rating));
    }, []);

    return rating ? (
        <div
            className="text-center d-flex flex-row justify-content-center cursor-pointer"
            onClick={() => {
                window.open(
                    "https://www.google.com/search?q=4mobiles&rlz=1C1ONGR_enIN1022IN1022&oq=4mobiles&aqs=chrome.0.69i59j69i60l4j5i44.1902j0j7&sourceid=chrome&ie=UTF-8#lrd=0x47c5b25e7bb1e8b7:0x2175e7ba4977687c,3,,,,"
                );
            }}
        >
            <GoogleIcon />
            <div className="p-0 cursor-pointer">
                <p className="google-reivew-text">Google Review</p>
                <div
                    className="cursor-pointer"
                    style={{
                        marginTop: "-5px",
                    }}
                >
                    <ReactStars
                        edit={false}
                        count={5}
                        size={20}
                        onChange={() => {}}
                        value={rating}
                        activeColor="#ffd700"
                        isHalf={true}
                    />
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
}
