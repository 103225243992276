import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowAltCircleRight,
    faClose,
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import Calender from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./styles.scss";
import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import StepProgress from "../../components/step-progress";
import { IProductModel } from "../../models/product-models";
import { IRepairItemModel } from "../../models/repair-item-models";
import { IProductColorModel } from "../../models/product-color-models";
import { INonWorkingDayModel } from "../../models/non-working-day-model";
import { MiscApi } from "../../AAL/misc-api";
import { ALLOWED_ORDER_NEXT_DAYS, CURRENCYSIGN } from "../../configs/constants";
import PageLoading from "../../components/page-loading/page-loading";
import ErrorModal from "../../components/modals/error-modal";
import SuccessModal from "../../components/modals/success-modal";
import { OrderApi } from "../../AAL/order-api";
import { HandleError } from "../../errors/handler";
import { Helmet } from "react-helmet";

export default function ConfirmOrderPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const {
        product,
        selectedRepairs,
        selectedColor,
    }: {
        product: IProductModel;
        selectedRepairs: IRepairItemModel[];
        selectedColor: IProductColorModel;
    } = location.state;
    const [timeSlots, setTimeSlots] = useState<string[]>([]);
    const [nonWorkingDays, setNonWorkingDays] =
        useState<INonWorkingDayModel[]>();
    const [loading, setLoading] = useState<boolean>(true);
    const [maxDate, setMaxDate] = useState<Date>(new Date());
    //form states
    const nameInputRef = useRef<HTMLInputElement>(null);
    const phoneInputRef = useRef<HTMLInputElement>(null);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const agreeCheckboxRef = useRef<HTMLInputElement>(null);
    const [name, setName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [agree, setAgree] = useState(false);
    const [error, setError] = useState("");
    const [visitStore, setVisitStore] = useState(true);
    const [visitDate, setVisitDate] = useState<Date | null>(null);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<string>("");
    const [selectedRepairsN, setSelectedRepairsN] =
        useState<IRepairItemModel[]>(selectedRepairs);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [submitingOrder, setSubmitingOrder] = useState<boolean>(false);

    useEffect(() => {
        const date = new Date();
        date.setDate(date.getDate() + ALLOWED_ORDER_NEXT_DAYS);
        setMaxDate(date);
        Promise.all([
            MiscApi.getNonWokringDays(new Date(), date),
            MiscApi.getTimeSlots(),
        ])
            .then((result) => {
                setNonWorkingDays(result[0]);
                setTimeSlots(result[1]);
            })
            .finally(() => {
                setLoading(false);
            });
        if (product && selectedColor && selectedRepairs?.length) {
        }
    }, [product, selectedColor, selectedRepairs]);

    const removeRepair = (id: number) => {
        const index = selectedRepairsN?.findIndex((x) => x.id === id);
        if (index >= 0) {
            selectedRepairsN.splice(index, 1);
            setSelectedRepairsN([...selectedRepairsN]);
        }
    };

    const submitOrder = async () => {
        try {
            setError("");
            if (validate()) {
                setSubmitingOrder(true);
                const resp = await OrderApi.create({
                    name,
                    phoneNumber,
                    email,
                    productId: product.id,
                    colorId: selectedColor.id,
                    visitingStore: visitStore,
                    visitingStoreDate: visitDate ?? undefined,
                    visitingStoreTime: selectedTimeSlot,
                    repairs: selectedRepairs.map((x) => ({ repairId: x.id })),
                });
                setShowSuccess(true);
            }
        } catch (error) {
            setError(HandleError(error, false));
        } finally {
            setSubmitingOrder(false);
        }
    };

    const validate = () => {
        if (!product?.id || !selectedColor?.id || !selectedRepairs?.length) {
            navigate("/");
        }

        if (!name) {
            //setError("Please enter name.");
            nameInputRef.current?.focus();
            return false;
        }

        if (!phoneNumber) {
            //setError("Please enter name.");
            phoneInputRef.current?.focus();
            return false;
        }

        if (!email) {
            //setError("Please enter name.");
            emailInputRef.current?.focus();
            return false;
        }

        if (!agree) {
            setError("accepteer voorwaarden")
            agreeCheckboxRef.current?.focus();            
            return false;
        }

        if (visitStore) {
            if (!visitDate) {
                setError("Please select store visit date.");
                return false;
            }

            if (!selectedTimeSlot) {
                setError("Please select timeslot.");
                return false;
            }
        }
        return true;
    };

    return (
        <Container className="brands-page-container">
            <Helmet>
                <title>Confirm Order</title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
            </Helmet>
            {/**Error Popup Modal */}
            <ErrorModal
                onClose={() => setError("")}
                show={error ? true : false}
                title={"Error"}
                message={error}
            />
            {/**Success message */}
            <SuccessModal
                footerText="Team 4Mobiles"
                onClose={() => {
                    setShowSuccess(false);
                    navigate("/", {
                        replace: true,
                    });
                }}
                showCloseBtn
                show={showSuccess}
                title={"Bevestiging"}
                message={
                    "Bedankt voor uw reparatie aanvraag we zien u graag in onze winkel!"
                }
            />

            {/**Breadcrumb */}
            <nav className="mt-2 mb-2">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <NavLink to="/" className="text-primary">
                            Home
                        </NavLink>
                    </li>
                    <li className="breadcrumb-item active">
                        Bevesting Details
                    </li>
                </ol>
            </nav>

            {/** Step Progress */}
            <StepProgress currentStep={4} />

            {loading ? (
                <PageLoading />
            ) : (
                <>
                    <Row className="mt-3">
                        {/**Form */}
                        <Col lg={6}>
                            <h5>Bevestiging Aanvraag</h5>
                            <Form className="mt-3">
                                <FormGroup>
                                    <label className="form-label">Naam</label>
                                    <input
                                        ref={nameInputRef}
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        required
                                        maxLength={50}
                                        type={"text"}
                                        className="form-control"
                                    />
                                </FormGroup>

                                <FormGroup className="mt-3">
                                    <label className="form-label">
                                        Telefoon nummer
                                    </label>
                                    <input
                                        ref={phoneInputRef}
                                        value={phoneNumber}
                                        onChange={(e) =>
                                            setPhoneNumber(e.target.value)
                                        }
                                        required
                                        type={"text"}
                                        className="form-control"
                                    />
                                </FormGroup>

                                <FormGroup className="mt-3">
                                    <label className="form-label">Email</label>
                                    <input
                                        ref={emailInputRef}
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        type={"email"}
                                        required
                                        maxLength={100}
                                        className="form-control"
                                    />
                                </FormGroup>

                                <div className="mt-3">
                                    <Form.Check
                                        ref={agreeCheckboxRef}
                                        checked={agree}
                                        onChange={() => setAgree(!agree)}
                                        type="checkbox"
                                        label="Ik heb de algemene voorwaarden gelezen en ga hiermee akkoord"
                                    />
                                </div>
                            </Form>
                        </Col>

                        {/**Repairs */}
                        <Col lg={6}>
                            <div className="repair-list-conainer p-3">
                                <h6 className="mt-3">Overzicht</h6>
                                <p className="mb-1 small">
                                    {`${product.name} (${product.model})`}
                                </p>

                                {/**Selected Color */}
                                <div className="select-color-container">
                                    <label className="text-bold small">
                                        Geslecteerde kleur
                                    </label>
                                    <div className="mt-1">
                                        <button
                                            className="select-color-btn"
                                            style={{
                                                backgroundColor:
                                                    selectedColor.colorCode,
                                            }}
                                        ></button>
                                    </div>
                                </div>

                                {/**Selected repairs */}
                                <div>
                                    {selectedRepairsN?.map((repair) => (
                                        <div
                                            key={repair.id}
                                            className="bg-custom p-3 pt-2 pb-2 mb-2"
                                        >
                                            <Row>
                                                <Col xs={8}>
                                                    <p className="mb-1">
                                                        {repair.title}
                                                    </p>
                                                    <span className="text-bold m-0 small">
                                                        Reparatietijd:
                                                    </span>{" "}
                                                    <span className="small">
                                                        {" "}
                                                        {repair.timeTake}
                                                    </span>
                                                </Col>
                                                <Col
                                                    xs={4}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    {selectedRepairsN?.length ===
                                                    1 ? (
                                                        <FontAwesomeIcon
                                                            className="btn-remove-repair"
                                                            style={{
                                                                cursor: "default",
                                                            }}
                                                            icon={faClose}
                                                            color="white"
                                                            size="xs"
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            onClick={() =>
                                                                removeRepair(
                                                                    repair.id
                                                                )
                                                            }
                                                            className="bg-danger btn-remove-repair disabled"
                                                            icon={faClose}
                                                            color="white"
                                                            size="xs"
                                                        />
                                                    )}

                                                    <p className="text-bold small m-0">
                                                        {repair.cost
                                                            ? `${CURRENCYSIGN} ${repair.cost?.toFixed(
                                                                  2
                                                              )}`
                                                            : "op aanvraag"}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </div>

                                <hr
                                    style={{
                                        borderStyle: "dashed",
                                    }}
                                />

                                {/**Total */}
                                <Row>
                                    <Col>
                                        {/*<p className="text-bold mb-0 small">
                                            Repair time:
                                        </p>
                                <p className="small">270 minutes</p>*/}
                                    </Col>
                                    <Col
                                        style={{
                                            textAlign: "right",
                                        }}
                                    >
                                        <p className="text-bold mb-0 small">
                                            Total
                                        </p>
                                        <p className="text-bold small">
                                            {` ${CURRENCYSIGN} ${
                                                selectedRepairs.find(
                                                    (x) =>
                                                        x.cost === undefined ||
                                                        x.cost === null
                                                )
                                                    ? "op aanvraag"
                                                    : selectedRepairs
                                                          .map((x) => x.cost)
                                                          .reduce(
                                                              (a, b) => a! + b!
                                                          )
                                            }`}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    {/**Schedule */}

                    <div>
                        <FormGroup className="mt-5">
                            <Form.Check
                                checked={visitStore}
                                onChange={() => {
                                    //setVisitStore(!visitStore);
                                }}
                                style={{
                                    fontWeight: "bold",
                                }}
                                label="Bezoek de winkel"
                            />
                        </FormGroup>
                        {visitStore ? (
                            <div className="schedule-container">
                                <Row className="mt-3">
                                    <Col lg={6}>
                                        <Calender
                                            tileDisabled={({
                                                activeStartDate,
                                                date,
                                                view,
                                            }) => {
                                                let todate = new Date();
                                                if (date.getDay() === 0)
                                                    return true;
                                                if (
                                                    date.setHours(
                                                        0,
                                                        0,
                                                        0,
                                                        0
                                                    ) ===
                                                    todate.setHours(0, 0, 0, 0)
                                                )
                                                    return false;
                                                if (date < todate) return true;
                                                if (date > maxDate) return true;

                                                if (
                                                    nonWorkingDays?.find(
                                                        (x) =>
                                                            x.date.setHours(
                                                                0,
                                                                0,
                                                                0,
                                                                0
                                                            ) ===
                                                            date.setHours(
                                                                0,
                                                                0,
                                                                0,
                                                                0
                                                            )
                                                    )
                                                )
                                                    return true;

                                                return false;
                                            }}
                                            className="schedule-calender"
                                            onClickDay={(v, e) => {
                                                setVisitDate(v);
                                            }}
                                        />
                                    </Col>
                                    <Col lg={6} className="p-4 pt-0">
                                        <div className="m-4 text-center">
                                            {visitDate ? (
                                                <>
                                                    <div className="selected-date-view">
                                                        {` ${visitDate.toLocaleString(
                                                            "default",
                                                            {
                                                                month: "short",
                                                            }
                                                        )}  ${visitDate.getDate()},  ${visitDate.getFullYear()}`}
                                                    </div>
                                                    <hr className="w-50 m-auto mt-2" />
                                                </>
                                            ) : undefined}
                                        </div>

                                        <div>
                                            <Row className="d-flex">
                                                {timeSlots.map((ts, i) => (
                                                    <Col
                                                        key={i}
                                                        xs={4}
                                                        className="d-flex"
                                                    >
                                                        <button
                                                            onClick={() =>
                                                                setSelectedTimeSlot(
                                                                    ts
                                                                )
                                                            }
                                                            className={`btn-time mb-3 flex-fill p-3 ${
                                                                ts ===
                                                                selectedTimeSlot
                                                                    ? "selected"
                                                                    : ""
                                                            }`}
                                                        >
                                                            {ts}
                                                        </button>
                                                    </Col>
                                                ))}
                                            </Row>
                                            <div className="text-center mt-5">
                                                <button
                                                    disabled={submitingOrder}
                                                    onClick={() =>
                                                        submitOrder()
                                                    }
                                                    className="btn btn-primary"
                                                >
                                                    {submitingOrder ? (
                                                        <>
                                                            <FontAwesomeIcon
                                                                icon={faSpinner}
                                                                spin
                                                            />{" "}
                                                            Placing order...
                                                        </>
                                                    ) : (
                                                        <>
                                                            Reparatie Aanvraag{" "}
                                                            <FontAwesomeIcon
                                                                size="xl"
                                                                icon={
                                                                    faArrowAltCircleRight
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        ) : (
                            <div className="text-center mt-5">
                                <button
                                    disabled={submitingOrder}
                                    onClick={() => submitOrder()}
                                    className="btn btn-primary"
                                >
                                    {submitingOrder ? (
                                        <>
                                            <FontAwesomeIcon
                                                icon={faSpinner}
                                                spin
                                            />{" "}
                                            Placing order...
                                        </>
                                    ) : (
                                        <>
                                            Reparatie Aanvraag{" "}
                                            <FontAwesomeIcon
                                                size="xl"
                                                icon={faArrowAltCircleRight}
                                            />
                                        </>
                                    )}
                                </button>
                            </div>
                        )}

                        <p className="footer-note-confirm-order">
                            Nog vragen of twijfels? Bel ons of loop gerust een
                            keer binnen
                        </p>
                    </div>
                </>
            )}
        </Container>
    );
}
