import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/esm/Form";
import Row from "react-bootstrap/esm/Row";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import useAuth from "../../context/auth-context";
import { HandleError } from "../../errors/handler";

export default function LoginPage() {
	const auth = useAuth();
	const navigate = useNavigate();
	const [email, setEmail] = useState<string>("");
	const [code, setCode] = useState<string>("");
	const [codeSent, setCodeSent] = useState<boolean>(false);
	const [error, setError] = useState<string>("");

	useEffect(() => {
		auth.logout();
	}, []);

	useEffect(() => {
		if (auth?.user?.token) {
			navigate("/my-orders");
		}
	}, [auth.user, navigate]);

	const submit = async (e: React.FormEvent<HTMLFormElement>) => {
		setError("");
		e.preventDefault();
		e.stopPropagation();
		try {
			if (codeSent && email && code) {
				await auth.login(email, code);
			} else {
				await auth.sendCode(email);
				setCodeSent(true);
			}
		} catch (error) {
			setError(HandleError(error, false)[""]);
		}
	};
	useEffect(() => {
		if (error) {
			setError("");
		}
	}, [email, code]);

	return (
		<Container className="py-5">
			<Helmet>
				<title>Login</title>
				<meta name="description" content="" />
				<meta name="keywords" content="" />
			</Helmet>
			<Row>
				<Col md={6} lg={4} className="m-auto">
					<Card className="my-5">
						<div className="text-center m-2 pt-2">
							<Card.Title>Verifieer Email</Card.Title>
						</div>
						<hr className="m-0" />
						{error ? (
							<div className="alert alert-danger m-3">
								{error}
							</div>
						) : undefined}
						<Form onSubmit={submit}>
							<Card.Body>
								<Form.Group>
									<Form.Label>
										Voer Email in (Waarmee de reparatie is
										aangemeld)
									</Form.Label>
									<Form.Control
										readOnly={codeSent}
										value={email}
										onChange={(e) =>
											setEmail(e.target.value)
										}
										required
										type="email"
										placeholder="Email"
									/>
								</Form.Group>
								{codeSent ? (
									<Form.Group className="mt-3">
										<Form.Label>
											Enter Code (Sent to your email)
										</Form.Label>
										<Form.Control
											autoFocus
											value={code}
											maxLength={10}
											onChange={(e) =>
												setCode(e.target.value)
											}
											required
											placeholder="Code"
										/>
									</Form.Group>
								) : (
									<></>
								)}
							</Card.Body>
							<div className="text-end p-3">
								<Button
									disabled={auth.loading}
									type="submit"
									variant="primary"
								>
									{auth.loading ? (
										<>
											<FontAwesomeIcon
												spin
												icon={faSpinner}
											/>{" "}
											Please wait...
										</>
									) : (
										"Verify"
									)}
								</Button>
							</div>
						</Form>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
