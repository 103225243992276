import "./styles.scss";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { HeaderImage } from "../../images/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { PRIMARYCOLOR } from "../../theme/colors";
import Select, { components } from "react-select";
import { IProductModel } from "../../models/product-models";
import { OptionProps } from "react-select";
import AppImage from "../image/img";
import { useEffect, useState } from "react";
import { ProductApi } from "../../AAL/product-api";
import { useNavigate } from "react-router-dom";

export function HomePageHeader() {
	const navigate = useNavigate();
	const [products, setProducts] = useState<IProductModel[]>([]);

	useEffect(() => {
		loadProducts();
	}, []);
	const loadProducts = async () => {
		try {
			const prods = await ProductApi.getAll();
			setProducts(prods);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Container>
			<Row>
				<Col lg={6} className="align-self-center order-2 order-lg-1">
					<div className="form-container">
						<p
							id="searchTitle"
							className="question-text"
							style={{
								scrollMargin: "70px",
							}}
						>
							Wat mogen wij voor u repareren?
						</p>

						<div className="input-group mt-3 d-flex flex-row ">
							<Select
								onFocus={() => {
									setTimeout(() => {
										document
											.getElementById("searchTitle")
											?.scrollIntoView({
												behavior: "smooth",
												inline: "nearest",
											});
									}, 700);
								}}
								filterOption={(option, val) => {
									if (val) {
										if (
											option.data.model
												?.toLowerCase()
												.includes(val.toLowerCase()) ||
											option.data.name
												?.toLowerCase()
												.includes(val.toLowerCase()) ||
											option.data.tags
												?.toLowerCase()
												.includes(val.toLowerCase())
										) {
											return true;
										}
										return false;
									} else {
										return true;
									}
								}}
								getOptionLabel={(p) => p.name}
								getOptionValue={(p) => p.id.toString()}
								components={{
									DropdownIndicator: () => null,
									IndicatorSeparator: () => null,
									Option: SearchInputOption,
								}}
								noOptionsMessage={() => "No model found..."}
								isMulti={false}
								onChange={(val) => {
									if (val) {
										navigate("/reparatie/" + val.slug);
									}
								}}
								options={products}
								placeholder="Zoek uw model"
								classNames={{
									control: (p) =>
										"form-control rounded-0 m-0 p-0",
									container: (p) =>
										"search-input flex-fill w-100",
								}}
								styles={{
									menuPortal: (base) => ({
										...base,
										zIndex: 100,
									}),
								}}
								menuPortalTarget={document.body}
							/>
							<div
								style={{
									position: "absolute",
									right: 0,
									height: "100%",
								}}
								className="input-group-text search-input-btn"
							>
								<FontAwesomeIcon
									color="white"
									icon={faSearch}
								/>
							</div>
						</div>

						<div className="mt-4">
							<Row>
								<Col>
									<div className="d-flex flex-row">
										<div>
											<FontAwesomeIcon
												color={PRIMARYCOLOR}
												icon={faCheckCircle}
												className="list-item-icon"
											/>
										</div>
										<div>
											<span className="list-item-text">
												Deskundige reparateurs
											</span>
										</div>
									</div>
								</Col>
								<Col>
									<div className="d-flex flex-row">
										<div>
											<FontAwesomeIcon
												className="list-item-icon"
												color={PRIMARYCOLOR}
												icon={faCheckCircle}
											/>
										</div>
										<div>
											<span className="list-item-text">
												Met en zonder afspraak
											</span>
										</div>
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className="d-flex flex-row">
										<div>
											<FontAwesomeIcon
												className="list-item-icon"
												color={PRIMARYCOLOR}
												icon={faCheckCircle}
											/>
										</div>
										<div>
											<span className="list-item-text">
												Klaar terwijl u wacht
											</span>
										</div>
									</div>
								</Col>
								<Col>
									<div className="d-flex flex-row">
										<div>
											<FontAwesomeIcon
												className="list-item-icon"
												color={PRIMARYCOLOR}
												icon={faCheckCircle}
											/>
										</div>
										<div>
											<span className="list-item-text">
												Transparante prijzen
											</span>
										</div>
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className="d-flex flex-row">
										<div>
											<FontAwesomeIcon
												className="list-item-icon"
												color={PRIMARYCOLOR}
												icon={faCheckCircle}
											/>
										</div>
										<div>
											<span className="list-item-text">
												Gratis Parkeren
											</span>
										</div>
									</div>
								</Col>
								<Col>
									<div className="d-flex flex-row">
										<div>
											<FontAwesomeIcon
												className="list-item-icon"
												color={PRIMARYCOLOR}
												icon={faCheckCircle}
											/>
										</div>
										<div>
											<span className="list-item-text">
												No cure, no pay
											</span>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</Col>
				<Col className="order-1 order-lg-2" lg={6}>
					<img
						alt=""
						className="img-fluid non-selectable"
						src={HeaderImage}
					/>
				</Col>
			</Row>
		</Container>
	);
}

const SearchInputOption = (item: OptionProps<IProductModel>) => {
	return (
		<components.Option
			{...item}
			className="d-flex flex-row align-items-center p-2"
		>
			<AppImage
				style={{
					height: 50,
					width: 50,
				}}
				name={item.data.thumbnail}
			/>
			<p className="ms-2 mb-0 flex-fill">{item.data.name}</p>
			<p className="m-0">{item.data?.model ?? ""}</p>
		</components.Option>
	);
};
