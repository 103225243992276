import { IBrandModel } from "../models/brand-models";
import { BaseApi } from "./base-api";

export class BrandApi extends BaseApi {
    private static url = (api: string) => `brand/${api}`;

    /**
     * Get Brand
     * @param id Brand id
     * @returns Brand
     */
    public static async get(id: number): Promise<IBrandModel> {
        return this._get(this.url('get'), {
            id
        });
    }

    /**
     * Get all brands
     * @returns List of brands
     */
    public static async getAll(): Promise<IBrandModel[]> {
        return this._get(this.url('getall'));
    }

    /**
     * Get all brands
     * @returns List of brands
     */
    public static async getAllByDeviceType(type: number): Promise<IBrandModel[]> {
        return this._get(this.url('GetBrandsByType'), {
            type
        });
    }

    /**
    * Get all brands
    * @returns List of brands
    */
    public static async getAllByDeviceTypeSlug(typeSlug: string): Promise<IBrandModel[]> {
        return this._get(this.url('GetBrandsByTypeSlug'), {
            slug: typeSlug
        });
    }
}