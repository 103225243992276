import { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import { ReactComponent as Logo } from "../../images/logo.svg";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import LogoSmall from "../../images/logo-small.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import { INavbarMenu } from "../../models/navbar-menu-model";
import { MiscApi } from "../../AAL/misc-api";
import useAuth from "../../context/auth-context";

export default function AppNavbar() {
	const navigate = useNavigate();
	const [repairMenu, setRepairMenu] = useState<INavbarMenu[]>([]);
	const [phoneNumber, setPhoneNumber] = useState<string>("");
	const { user, logout } = useAuth();

	useEffect(() => {
		loadData();
	}, []);

	const loadData = async () => {
		try {
			var result = await Promise.all([
				MiscApi.getNavbarMenus(),
				MiscApi.getPhoneNumbers(),
			]);
			setRepairMenu(result[0]);
			setPhoneNumber(result[1]);
		} catch (error) {}
	};

	return (
		<>
			<Navbar
				collapseOnSelect={true}
				className="navbar-c p-0"
				expand="lg"
				style={{
					zIndex: 999,
				}}
			>
				<Container className="align-items-lg-end gap-lg-4">
					<Navbar.Toggle className="nav-toggle-btn p-0">
						<svg
							width="27"
							height="27"
							viewBox="0 0 27 27"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M27 6.7502V2.7002H0V6.7502H27ZM27 14.8502V10.8002H0V14.8502H27ZM27 22.9502V18.9002H0V22.9502H27Z"
								fill="white"
							/>
						</svg>
					</Navbar.Toggle>
					<Navbar.Brand href="/" className="mx-auto">
						<Logo
							className="d-none d-lg-block"
							height={80}
							width={140}
						/>

						<img
							className="d-lg-none nav-mobile-logo"
							src={LogoSmall}
							alt="logo"
						/>
					</Navbar.Brand>

					<Navbar.Offcanvas>
						<Offcanvas.Header closeButton></Offcanvas.Header>
						<Offcanvas.Body>
							<Nav className="me-auto">
								<Nav.Link eventKey={0} as={NavLink} to="/">
									<FontAwesomeIcon icon={faHome} />
								</Nav.Link>

								{repairMenu?.length ? (
									<NavDropdown title="Reparaties">
										{repairMenu?.map((m, i) => (
											<Nav.Link
												eventKey={i + 6}
												href={m.link}
												key={i}
											>
												{m.text}
											</Nav.Link>
										))}
									</NavDropdown>
								) : (
									<Nav.Link href="#deviceTypeOptions">
										Reparaties
									</Nav.Link>
								)}

								<Nav.Link
									eventKey={3}
									as={NavLink}
									to="/zakelijk"
								>
									Zakelijk
								</Nav.Link>

								<Nav.Link
									eventKey={4}
									as={NavLink}
									to="/contact"
								>
									Contact
								</Nav.Link>

								<Nav.Link eventKey={5} as={NavLink} to="/faq">
									Veelgestelde vragen
								</Nav.Link>
							</Nav>
							<Nav className="ms-auto">
								<Nav.Link
									eventKey={21}
									as={NavLink}
									to="/my-orders"
									className="my-order-menu"
								>
									Mijn Reparatie
								</Nav.Link>
								{user ? (
									<Nav.Link
										eventKey={22}
										onClick={() => {
											logout();
											navigate("/");
										}}
									>
										Logout
									</Nav.Link>
								) : undefined}
							</Nav>
						</Offcanvas.Body>
					</Navbar.Offcanvas>

					<Nav className="d-lg-none">
						<a
							href={`tel:${phoneNumber?.split(",")?.[0]}`}
							className="btn btn-sm btn-call-us"
						>
							Bel ons
						</a>
					</Nav>
				</Container>
			</Navbar>
		</>
	);
}
