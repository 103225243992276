import { IUser } from "../models/user-model";
import { BaseApi } from "./base-api";

export default class AuthApi extends BaseApi {
    private static url = (action: string) => 'auth/' + action;

    /**
     * generate code
     * @param username 
     * @param password 
     * @returns User
     */
    public static async sendCode(email: string): Promise<void> {
        return this._post(this.url('clientlogin'), {
            email
        });
    }

    /**
     * verify
     * @returns 
     */
    public static async verifyCode(email: string, code: string): Promise<IUser> {
        return this._post(this.url('VerifyClientOTP'), {
            email,
            code
        });
    }
}