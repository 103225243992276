import { NavLink } from "react-router-dom";
import AppImage from "../image/img";
import "./styles.scss";

export type TImageItemCardProps = {
    onClick?: () => void;
    imageName: string;
    text?: string;
    subText?: string;
    to: string;
};

export default function ImageItemCard(props: TImageItemCardProps) {
    return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <NavLink
            to={props.to}
            className="h-100"
            style={{
                cursor: "pointer",
            }}
            onClick={() => props.onClick?.()}
        >
            <div className="brand-card text-center h-100 d-flex flex-column">
                <div className="d-flex flex-column flex-fill justify-content-center">
                    <AppImage className="img-fluid " name={props.imageName} />
                </div>

                <div>
                    {props.text ? (
                        <p
                            className="title"
                            style={{
                                color: "black",
                                fontWeight: "600",
                            }}
                        >
                            {props.text}
                        </p>
                    ) : undefined}

                    {props.subText ? (
                        <p className="subtitle">{props.subText}</p>
                    ) : undefined}
                </div>
            </div>
        </NavLink>
    );
}
