import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { ContactPageImage } from "../../images/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEnvelope,
	faLocationDot,
	faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { MiscApi } from "../../AAL/misc-api";
import { IPageContent } from "../../models/shared-models";
import { Helmet } from "react-helmet";
import { Card } from "react-bootstrap";
import EnquiryForm from "../../components/enquiry-form";
import { NavLink } from "react-router-dom";

export default function ContactPage() {
	const [loading, setLoading] = useState<boolean>(false);
	const [address, setAddress] = useState<string>("");
	const [seoContent, setSeoContent] = useState<IPageContent>();
	const [phoneNumbers, setPhoneNumbers] = useState<string>("");
	const [emails, setEmails] = useState<string>("");
	const [facebookUrl, setFacebookUrl] = useState<string>("");
	const [instagramUrl, setInstagramUrl] = useState<string>("");
	const [whatsAppNumber, setWhatsAppNumber] = useState<string>("");

	useEffect(() => {
		MiscApi.getContactPageContent()
			.then((d) => setSeoContent(d))
			.catch();
		loadData();
	}, []);

	const loadData = async () => {
		try {
			setLoading(true);
			var result = await Promise.all([
				MiscApi.getAddress(),
				MiscApi.getPhoneNumbers(),
				MiscApi.getEmails(),
				MiscApi.getSocialLinks(),
			]);
			setAddress(result[0]);
			setPhoneNumbers(result[1]);
			setEmails(result[2]);
			if (result[3]) {
				setFacebookUrl(result[3].facebook ?? "");
				setInstagramUrl(result[3].instagram ?? "");
				setWhatsAppNumber(result[3].whatsAppNumber ?? "");
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};
	return (
		<Container>
			{seoContent ? (
				<Helmet>
					<title>{seoContent?.title}</title>
					<meta name="description" content={seoContent.description} />
					<meta name="keywords" content={seoContent.keywords} />
				</Helmet>
			) : undefined}
			{/**Breadcrumb */}
			<nav className="mt-2 mb-2">
				<ol className="breadcrumb">
					<li className="breadcrumb-item active">
						<NavLink to="/" className="text-primary">
							Home
						</NavLink>
					</li>
					<li className="breadcrumb-item active">Contact</li>
				</ol>
			</nav>

			{/**Content */}
			{loading ? (
				<div className="text-center my-5">
					<Spinner variant="primary" className="my-5" />
				</div>
			) : (
				<Row>
					<Col
						lg={{
							span: 6,
							order: 1,
						}}
						xs={{
							order: 2,
						}}
						className="pt-5"
					>
						<h6 className="mb-0 text-bold">
							<FontAwesomeIcon icon={faLocationDot} /> Address 1:
						</h6>
						<p
							style={{
								marginLeft: 15,
							}}
							className="small"
							dangerouslySetInnerHTML={{
								__html: address,
							}}
						></p>

						<h6 className="mb-0 mt-4 text-bold">
							<FontAwesomeIcon icon={faPhone} size="sm" /> Phone
							Number
						</h6>
						<p
							className="small"
							style={{
								marginLeft: 15,
								marginBottom: 0,
							}}
							dangerouslySetInnerHTML={{
								__html: phoneNumbers,
							}}
						></p>
						<h6 className="mb-0 mt-4 text-bold">
							<FontAwesomeIcon icon={faEnvelope} size="sm" />{" "}
							Email
						</h6>
						<p
							className="small"
							style={{
								marginLeft: 15,
								marginBottom: 0,
							}}
							dangerouslySetInnerHTML={{
								__html: emails,
							}}
						></p>

						<div
							style={{
								marginLeft: 15,
							}}
						>
							<a
								target="_blank"
								href={`https://wa.me/${whatsAppNumber}`}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="34"
									height="33"
									viewBox="0 0 34 33"
									fill="none"
								>
									<path
										d="M17 6.87422C11.4826 6.87422 6.99732 11.1451 6.98973 16.3988C6.98973 18.1982 7.52098 19.9543 8.52277 21.4646L8.75804 21.826L7.74866 25.3381L11.5357 24.3914L11.9 24.601C13.433 25.4682 15.1937 25.9307 16.9924 25.9307H17C22.5098 25.9307 27.1165 21.6598 27.1165 16.4061C27.1165 13.8623 25.9629 11.4703 24.0732 9.6709C22.1759 7.86426 19.6714 6.87422 17 6.87422ZM22.8817 20.4891C22.6313 21.1611 21.4321 21.7682 20.8554 21.8477C19.8991 21.985 19.1554 21.9127 17.2504 21.1322C14.2375 19.8893 12.2643 16.9986 12.1125 16.8107C11.9607 16.6229 10.883 15.257 10.883 13.8479C10.883 12.4387 11.6571 11.7449 11.9379 11.4559C12.2112 11.1668 12.5375 11.0945 12.7424 11.0945C12.9397 11.0945 13.1446 11.0945 13.3192 11.1018C13.5013 11.109 13.7518 11.0367 13.9946 11.5932C14.2451 12.1641 14.8446 13.5732 14.9205 13.7178C14.9964 13.8623 15.0496 14.0285 14.9433 14.2164C14.3665 15.3148 13.7518 15.2715 14.0629 15.7773C15.2241 17.6779 16.3853 18.3355 18.1536 19.1811C18.4571 19.3256 18.6317 19.3039 18.8062 19.1088C18.9808 18.9209 19.5576 18.2705 19.7549 17.9887C19.9522 17.6996 20.1571 17.7502 20.4304 17.8441C20.7036 17.9381 22.1835 18.6318 22.4871 18.7764C22.7906 18.9209 22.9879 18.9932 23.0638 19.1088C23.1321 19.2461 23.1321 19.8242 22.8817 20.4891ZM30.3571 0.3125H3.64286C1.6317 0.3125 0 1.86621 0 3.78125V29.2188C0 31.1338 1.6317 32.6875 3.64286 32.6875H30.3571C32.3683 32.6875 34 31.1338 34 29.2188V3.78125C34 1.86621 32.3683 0.3125 30.3571 0.3125ZM16.9924 27.8602C14.9737 27.8602 12.9929 27.376 11.2397 26.4654L4.85714 28.0625L6.56473 22.1223C5.50982 20.3879 4.9558 18.415 4.9558 16.3916C4.96339 10.0756 10.3594 4.9375 16.9924 4.9375C20.2103 4.9375 23.2308 6.12988 25.5076 8.29785C27.7768 10.4658 29.1429 13.342 29.1429 16.4061C29.1429 22.7221 23.6254 27.8602 16.9924 27.8602Z"
										fill="black"
									/>
								</svg>
							</a>
							<a target="_blank" href={facebookUrl}>
								<svg
									className="m-3"
									xmlns="http://www.w3.org/2000/svg"
									width="36"
									height="35"
									viewBox="0 0 36 35"
									fill="none"
								>
									<path
										d="M31.3004 0.85H4.70039C2.61039 0.85 0.900391 2.515 0.900391 4.55V30.45C0.900391 32.4868 2.61039 34.15 4.70039 34.15H18.0004V21.2H14.2004V16.6212H18.0004V12.8287C18.0004 8.82535 20.3032 6.01335 25.1558 6.01335L28.5815 6.01705V10.8363H26.3072C24.4186 10.8363 23.7004 12.2164 23.7004 13.4966V16.6231H28.5796L27.5004 21.2H23.7004V34.15H31.3004C33.3904 34.15 35.1004 32.4868 35.1004 30.45V4.55C35.1004 2.515 33.3904 0.85 31.3004 0.85Z"
										fill="black"
									/>
								</svg>
							</a>
							<a
								target="_blank"
								href={`mailto:${emails?.split(",")?.[0]}`}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="47"
									height="48"
									viewBox="0 0 47 48"
									fill="none"
								>
									<g clip-path="url(#clip0_216_12501)">
										<path
											d="M42.2086 8.54043C42.0306 8.52209 41.8512 8.52209 41.6733 8.54043H5.11773C4.88344 8.54404 4.6507 8.57917 4.42578 8.64487L23.2911 27.4318L42.2086 8.54043Z"
											fill="black"
										/>
										<path
											d="M44.1406 10.3551L25.1318 29.2857C24.6425 29.772 23.9807 30.045 23.2909 30.045C22.6011 30.045 21.9393 29.772 21.4501 29.2857L2.61092 10.4988C2.553 10.7116 2.5223 10.931 2.51953 11.1515V37.2626C2.51953 37.9552 2.79463 38.6193 3.28431 39.109C3.77399 39.5987 4.43813 39.8738 5.13064 39.8738H41.6862C42.3787 39.8738 43.0429 39.5987 43.5325 39.109C44.0222 38.6193 44.2973 37.9552 44.2973 37.2626V11.1515C44.2869 10.8795 44.2341 10.6108 44.1406 10.3551ZM6.91925 37.2626H5.10453V35.3957L14.5959 25.9826L16.4368 27.8235L6.91925 37.2626ZM41.6601 37.2626H39.8323L30.3148 27.8235L32.1556 25.9826L41.647 35.3957L41.6601 37.2626Z"
											fill="black"
										/>
									</g>
									<defs>
										<clipPath id="clip0_216_12501">
											<rect
												width="47"
												height="47"
												fill="white"
												transform="translate(0 0.707092)"
											/>
										</clipPath>
									</defs>
								</svg>
							</a>
						</div>
					</Col>
					<Col
						lg={{
							span: 6,
							order: 2,
						}}
						xs={{
							order: 1,
						}}
					>
						<img
							src={ContactPageImage}
							className="img-fluid"
							alt=""
						/>
					</Col>
				</Row>
			)}
			<div className="contact-map-container mt-3 h-100">
				<iframe
					title="map"
					width="100%"
					height="400"
					scrolling="no"
					src="https://maps.google.com/maps?width=100%25&height=400&hl=en&q=4Mobiles+Molenstraat+2%2C+2671+EX+Naaldwijk%2C+Netherlands&t=&z=14&ie=UTF8&iwloc=B&output=embed"
				>
					<a href="https://www.maps.ie/distance-area-calculator.html">
						measure distance on map
					</a>
				</iframe>
			</div>
			<hr />
			<Row>
				<Col className="mx-auto" md={6}>
					<Card className="bg-primary my-5 text-white">
						<Card.Body>
							<EnquiryForm />
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<div
				className="mt-4 ql-editor"
				dangerouslySetInnerHTML={{
					__html: seoContent?.content ?? "",
				}}
			></div>
		</Container>
	);
}
