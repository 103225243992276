import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./styles.scss";
import SideNavMenuList from "../../components/side-menu-list";
import ImageItemCard from "../../components/image-item-card";
import { NavLink, useParams } from "react-router-dom";
import StepProgress from "../../components/step-progress";
import { useEffect, useState } from "react";
import { BrandApi } from "../../AAL/brand-api";
import { IBrandModel } from "../../models/brand-models";
import ReactPaginate from "react-paginate";
import { BRANDS_PER_PAGE_COUNT } from "../../configs/constants";
import PageLoading from "../../components/page-loading/page-loading";
import { IDeviceTypeModel } from "../../models/device-type-models";
import { IPageContent } from "../../models/shared-models";
import { DeviceTypeApi } from "../../AAL/device-type-api";
import { Helmet } from "react-helmet";

export default function BrandsPage() {
	const { type } = useParams();
	const [deviceType, setDeviceType] = useState<IDeviceTypeModel>();
	const [brands, setBrands] = useState<IBrandModel[]>([]);
	const [seoContent, setSeoContent] = useState<IPageContent>();
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		if (type) {
			DeviceTypeApi.getAll().then((dt) => {
				const t = dt.find((x) => x.slug === type);
				if (t) {
					setSeoContent({
						title: t.pageTitle,
						description: t.metaDescription,
						keywords: t.metaKeyword,
					});
				}
			});
			BrandApi.getAllByDeviceTypeSlug(type)
				.then((b) => {
					if (b?.length) {
						setBrands(b.sort((a, b) => a.order - b.order));
					}
				})
				.catch((err) => {})
				.finally(() => setLoading(false));
		} else {
			BrandApi.getAll()
				.then((b) => setBrands(b))
				.catch((err) => {})
				.finally(() => setLoading(false));
		}
	}, [type]);

	//pagination
	const [itemsOffset, setItemsOffset] = useState<number>(0);
	const pageCount = Math.ceil(brands.length / BRANDS_PER_PAGE_COUNT);
	const endOffset = itemsOffset + BRANDS_PER_PAGE_COUNT;
	const currentPageItems = brands.slice(itemsOffset, endOffset);

	const handlePageClick = (event: { selected: number }) => {
		const newOffset =
			(event.selected * BRANDS_PER_PAGE_COUNT) % brands.length;
		setItemsOffset(newOffset);
		window.scrollTo(0, 0);
	};

	return (
		<Container className="brands-page-container">
			{seoContent ? (
				<Helmet>
					<title>{seoContent?.title}</title>
					<meta name="description" content={seoContent.description} />
					<meta name="keywords" content={seoContent.keywords} />
				</Helmet>
			) : (
				<Helmet>
					<title>Brands</title>
					<meta name="description" content="" />
					<meta name="keywords" content="" />
				</Helmet>
			)}
			{/**Breadcrumb */}
			<nav className="mt-2 mb-2">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<NavLink to="/" className="text-primary">
							Home
						</NavLink>
					</li>
					<li className="breadcrumb-item active">Merk</li>
				</ol>
			</nav>

			{/** Step Progress */}
			<StepProgress currentStep={1} />
			{loading ? (
				<PageLoading />
			) : currentPageItems?.length ? (
				<Row className="mt-3">
					{/**Sidebar (menu) */}
					<Col className="d-none d-md-block" lg={2} md={3}>
						<SideNavMenuList
							title="Merk"
							list={brands?.slice(0, 10).map((t) => ({
								text: t.name,
								to: type
									? "/models/" +
									  type +
									  "/" +
									  encodeURIComponent(
											t.slug.replaceAll(" ", "-")
									  )
									: "/models/" +
									  encodeURIComponent(
											t.slug.replaceAll(" ", "-")
									  ),
							}))}
						/>
					</Col>
					{/**brands */}
					<Col sm={12} lg={10} md={9}>
						<Row>
							{currentPageItems.map((b) => (
								<Col
									key={b.id}
									className="mb-4 text-center"
									xs={6}
									md={3}
									sm={4}
									lg={2}
								>
									<ImageItemCard
										to={
											type
												? "/models/" +
												  type +
												  "/" +
												  encodeURIComponent(
														b.slug.replaceAll(
															" ",
															"-"
														)
												  )
												: "/models/" +
												  encodeURIComponent(
														b.slug.replaceAll(
															" ",
															"-"
														)
												  )
										}
										imageName={b.thumbnail}
										text={b.name}
									/>
								</Col>
							))}
						</Row>
						<div className="d-flex justify-content-center mt-4">
							<ReactPaginate
								breakLabel="..."
								nextLabel=">>"
								onPageChange={handlePageClick}
								pageRangeDisplayed={5}
								pageCount={pageCount}
								previousLabel="<<"
								containerClassName="pagination"
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								nextClassName="page-item"
								previousLinkClassName="page-link"
								nextLinkClassName="page-link"
								activeClassName="active"
							/>
						</div>
					</Col>
				</Row>
			) : (
				<p className="text-center">No brands found...</p>
			)}
		</Container>
	);
}
