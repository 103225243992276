import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { TickVectorPrimary } from "../../images/images";
import { useEffect, useState } from "react";
import { MiscApi } from "../../AAL/misc-api";
import Why4MobileImg from "../../images/why4mobile.jpg";

export default function Why4Mobile() {
	const [whatsAppNumber, setWhatsAppNumber] = useState<string>("");

	useEffect(() => {
		MiscApi.getSocialLinks().then((d) =>
			setWhatsAppNumber(d.whatsAppNumber ?? "")
		);
	}, []);
	return (
		<div className="h-100 mt-5 mt-lg-0 d-flex flex-column">
			<p className="device-type-title text-center my-4 mb-5">
				Waarom 4Mobiles?
			</p>
			<Row className="g-5 flex-fill">
				<Col xs={12} sm={8} lg={12} className="mx-auto">
					<Card className="shadow h-100">
						<Card.Img src={Why4MobileImg} />
						<Card.Body>
							<h4 className="text-bold">Ruim 10 jaar actief</h4>
							<ul className="list-unstyled">
								<li className="my-2">
									<img alt="" src={TickVectorPrimary} />{" "}
									Deskundige reparateurs
								</li>
								<li className="my-2">
									<img alt="" src={TickVectorPrimary} /> Met
									en zonder afspraak
								</li>
								<li className="my-2">
									<img alt="" src={TickVectorPrimary} /> Klaar
									terwijl u wacht
								</li>
								<li className="my-2">
									<img alt="" src={TickVectorPrimary} />{" "}
									Transparante prijzen
								</li>
								<li className="my-2">
									<img alt="" src={TickVectorPrimary} />{" "}
									Gratis Parkeren
								</li>
								<li className="my-2">
									<img alt="" src={TickVectorPrimary} /> No
									cure no pay
								</li>
								<li className="my-2">
									<img alt="" src={TickVectorPrimary} /> 90
									dagen garantie
								</li>
							</ul>

							<h5 className="text-bold text-center my-4">
								Nog vragen of twijfels?
							</h5>
							<div id="btc1">
								<div className="text-center">
									<a
										target="_blank"
										href={`https://wa.me/${whatsAppNumber}`}
										className="btn btn-primary rounded-3 w-75"
									>
										Start een gesprek
									</a>
								</div>
								<div className="text-center">
									<a
										href="tel:0174237022"
										className="btn text-white btn-info rounded-3 mt-2 w-75"
										style={{
											backgroundColor: "#828282",
										}}
									>
										Bel 0174-237022
									</a>
								</div>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
