import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import { MiscApi } from "../../AAL/misc-api";
import {
    ForBusinessPageImage,
    ForBusinessPageMobileImage,
} from "../../images/images";
import "./styles.scss";
import { IPageContent } from "../../models/shared-models";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

export default function ForBusinessPage() {
    const [seoContent, setSeoContent] = useState<IPageContent>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            setLoading(true);
            const data = await MiscApi.getCommercialPageContent();
            setSeoContent(data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {seoContent ? (
                <Helmet>
                    <title>{seoContent?.title}</title>
                    <meta name="description" content={seoContent.description} />
                    <meta name="keywords" content={seoContent.keywords} />
                </Helmet>
            ) : undefined}
            <Container>
                {/**Breadcrumb */}
                <nav className="mt-2 mb-2">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <NavLink to="/" className="text-primary">
                                Home
                            </NavLink>
                        </li>
                        <li className="breadcrumb-item active">Zakelijk</li>
                    </ol>
                </nav>

                {loading ? (
                    <div className="text-center my-5">
                        <Spinner variant="primary" className="my-5" />
                    </div>
                ) : (
                    <div
                        className="mt-5 ql-editor"
                        dangerouslySetInnerHTML={{
                            __html: seoContent?.content ?? "",
                        }}
                    ></div>
                )}
            </Container>
            <div
                className="bg-primary"
                style={{
                    marginBottom: "-50px",
                    marginTop: "80px",
                }}
            >
                <img
                    src={ForBusinessPageImage}
                    className="d-none d-lg-block w-100"
                    alt=""
                />
                <img
                    src={ForBusinessPageMobileImage}
                    className="d-lg-none w-100"
                    alt=""
                />
            </div>
        </>
    );
}
