import { IDeviceTypeModel } from "../models/device-type-models";
import { BaseApi } from "./base-api";

export class DeviceTypeApi extends BaseApi {
    private static url = (api: string) => `devicetype/${api}`;

    /**
     * Get DeviceType
     * @param id DeviceType id
     * @returns Device Type
     */
    public static async get(id: number): Promise<IDeviceTypeModel> {
        return this._get(this.url('get'), {
            id
        });
    }

    /**
     * Get all device types
     * @returns List of DeviceType
     */
    public static async getAll(): Promise<IDeviceTypeModel[]> {
        return this._get(this.url('getall'));
    }
}