import Footer from "../../components/footer";
import AppNavbar from "../../components/navbar";
import TopBar from "../../components/top-bar";
import { Link, Outlet } from "react-router-dom";
import ScrollToTop from "../../components/scroll-to-top";
import CookieConsent from "react-cookie-consent";
import { ReactComponent as WaLogo } from "../../images/whatsapp.svg";
import { useEffect, useState } from "react";
import { MiscApi } from "../../AAL/misc-api";

export default function LandingMaster() {
	const [whatsAppNumber, setWhatsAppNumber] = useState<string>("");
	useEffect(() => {
		MiscApi.getSocialLinks().then((d) =>
			setWhatsAppNumber(d.whatsAppNumber ?? "")
		);
	}, []);
	return (
		<>
			<ScrollToTop />
			<TopBar />
			<AppNavbar />

			{/** Router Outlet */}
			<div className="pt-5 pt-lg-0 mt-3 mt-lg-0">
				<Outlet />
			</div>
			{/**Footer */}
			<Footer />
			<CookieConsent
				location="bottom"
				buttonText="Akkoord"
				cookieName="4m-cookie"
				buttonClasses="btn btn-primary"
				expires={15}
			>
				Wij, en derde partijen, maken op onze websites gebruik van
				cookies. Wij gebruiken cookies voor het bijhouden van
				statistieken, om jouw voorkeuren op te slaan, maar ook voor
				marketingdoeleinden. Door 'Akkoord' aan te klikken, ga je
				akkoord met het gebruik van alle cookies zoals omschreven in
				<Link to={"/privacy-policy"}> onze cookieverklaring</Link>
			</CookieConsent>
			<a
				target="_blank"
				href={`https://wa.me/${whatsAppNumber}`}
				className="floating-wa"
			>
				<WaLogo />
			</a>
		</>
	);
}
