import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAdd,
	faArrowAltCircleRight,
	faClose,
} from "@fortawesome/free-solid-svg-icons";
import StepProgress from "../../components/step-progress";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IProductModel } from "../../models/product-models";
import { ProductApi } from "../../AAL/product-api";
import { IRepairItemModel } from "../../models/repair-item-models";
import { IRepairCategoryModel } from "../../models/repair-category-models";
import { RepairItemApi } from "../../AAL/repair-item-api";
import PageLoading from "../../components/page-loading/page-loading";
import AppImage from "../../components/image/img";
import { StockStatus } from "../../enums/stock-status";
import { CURRENCYSIGN } from "../../configs/constants";
import { IProductColorModel } from "../../models/product-color-models";
import { Helmet } from "react-helmet";
import Accordion from "react-bootstrap/esm/Accordion";
import Card from "react-bootstrap/esm/Card";
import { MiscApi } from "../../AAL/misc-api";
import Why4Mobile from "../../components/why-4mobiles";

export default function RepairsPage() {
	const { slug } = useParams();
	const navigate = useNavigate();
	const [product, setProduct] = useState<IProductModel>();
	const [selectedColor, setSelectedColor] = useState<IProductColorModel>();
	const [loadingProduct, setLoadingProduct] = useState<boolean>(true);
	const [loadingRepairs, setLoadingRepairs] = useState<boolean>(true);
	const [repairs, setRepairs] = useState<IRepairItemModel[]>([]);
	const [selectedRepairs, setSelectedRepairs] = useState<IRepairItemModel[]>(
		[]
	);
	const [activeAcKey, setActiveAcKey] = useState<string>("");
	const [repairCategories, setRepairCategories] = useState<
		IRepairCategoryModel[]
	>([]);
	const [seoText, setSeoText] = useState<string>("");

	const [error, setError] = useState<string>("");

	useEffect(() => {
		if (product) {
			MiscApi.getRepairPageSeoText().then((text) => {
				let t = text
					.replaceAll("{product}", product.name)
					?.replaceAll("{model}", product.model ?? product.name)
					.replaceAll("{brand}", product.brand.name);
				setSeoText(t);
			});
		}
	}, [product]);

	useEffect(() => {
		if (slug) {
			ProductApi.getBySlug(slug)
				.then((p) => {
					setProduct(p);
					setSelectedColor(p.colors?.[0]);
					RepairItemApi.getAllByProduct(Number(p.id))
						.then((reps) => {
							const cats: IRepairCategoryModel[] = [];
							reps.forEach((r) => {
								if (
									cats.findIndex(
										(x) => x.id === r.repairCategory.id
									) < 0
								) {
									cats.push(r.repairCategory);
								}
							});
							setRepairCategories(
								cats?.sort((a, b) => a.order - b.order)
							);
							setRepairs(reps?.sort((a, b) => a.order - b.order));
						})
						.catch()
						.finally(() => setLoadingRepairs(false));
				})
				.catch()
				.finally(() => setLoadingProduct(false));
		}
	}, [slug]);

	const addRepair = (repair: IRepairItemModel) => {
		setSelectedRepairs([...selectedRepairs, repair]);
		const sumNode = document.getElementById("summary");
		if (sumNode) {
			sumNode.scrollIntoView();
		}
	};

	const removeRepair = (id: number) => {
		const index = selectedRepairs?.findIndex((x) => x.id === id);
		if (index >= 0) {
			selectedRepairs.splice(index, 1);
			setSelectedRepairs([...selectedRepairs]);
		}
	};

	const confirmOrder = () => {
		if (!selectedRepairs?.length) {
			setError("Please select repair.");
			return;
		}

		if (!selectedColor) {
			setError("Please select color.");
			return;
		}

		navigate("/bevestiging", {
			state: {
				selectedRepairs,
				product,
				selectedColor,
			},
		});
	};

	const categoryBtnClick = (cat: number) => {
		setActiveAcKey(cat.toString());
		const node = document.getElementById(`ac_id_${cat}`);
		if (node) {
			node.scrollIntoView({
				behavior: "smooth",
			});
		}
	};

	return (
		<Container className="brands-page-container">
			{/**Helmet */}
			<Helmet>
				<title>{product?.pageTitle ?? ""}</title>
				<meta name="description" content={product?.metaDescription} />
				<meta name="keywords" content={product?.metaKeyword} />
			</Helmet>

			{/**Breadcrumb */}
			<nav className="mt-2 mb-2">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<NavLink to="/" className="text-primary">
							Home
						</NavLink>
					</li>
					<li className="breadcrumb-item">Repairs</li>
				</ol>
			</nav>

			{/** Step Progress */}
			<StepProgress currentStep={3} />

			{/** Content */}

			{loadingProduct || loadingRepairs ? (
				<PageLoading />
			) : product && repairs ? (
				<>
					<div className="mt-2">
						<p className="m-0 d-none d-lg-block">
							<span className="model-name">{product.name}</span>{" "}
							<small>{product.model}</small>
						</p>
					</div>
					{/**Mobile Title */}
					<div className="d-lg-none bg-primary model-title-container-mobile">
						<div className="d-flex flex-row">
							<AppImage
								className="img-fluid model-title-mobile-img"
								name={product?.thumbnail}
							/>

							<div className="ps-2">
								<p className="model-name-mobile mb-0 m-1 text-white">
									{product.name}
								</p>
								<p className="model-number-mobile mt-0 m-1 text-white">
									{product.model}
								</p>
							</div>
						</div>
					</div>
					<div className="d-none d-lg-block">
						<Row className="mt-3">
							{/**Product Image */}
							<Col
								lg={3}
								xl={2}
								className="d-flex flex-column justify-content-center"
							>
								<div className="d-none d-lg-block">
									<AppImage
										className="img-fluid w-100"
										name={product.thumbnail}
									/>
								</div>
							</Col>
							{/**Product Info */}
							<Col xl={10} lg={9} className="p-3">
								{repairCategories?.length ? (
									<Row className="g-3">
										{repairCategories.map((cat) => (
											<Col md={4} key={cat.id}>
												<button
													onClick={() =>
														categoryBtnClick(cat.id)
													}
													className="btn category-btn w-100"
												>
													<AppImage
														style={{
															height: 25,
															width: 25,
														}}
														className="m-1"
														name={cat.icon}
													/>
													<p className="mb-0 ms-2">
														{cat.name}
													</p>
												</button>
											</Col>
										))}
									</Row>
								) : (
									<p className="text-center">
										Er zijn geen reparatieartikelen
										beschikbaar...
									</p>
								)}
							</Col>
						</Row>
					</div>
					<Row className="mt-5">
						<Col lg={7}>
							<Accordion
								activeKey={activeAcKey}
								onSelect={(ek, e) =>
									setActiveAcKey(ek?.toString() ?? "")
								}
								defaultActiveKey={""}
								className="category-accrodian"
								flush
							>
								{repairCategories?.map((cat) => (
									<Accordion.Item
										id={`ac_id_${cat.id}`}
										className={`my-3 ${
											activeAcKey === cat.id.toString()
												? "active-accordian-item"
												: ""
										}`}
										key={cat.id}
										eventKey={cat.id.toString()}
									>
										<Accordion.Header>
											<AppImage
												style={{
													height: 25,
													width: 25,
												}}
												className="me-3"
												name={cat.icon}
											/>
											{cat.name}
										</Accordion.Header>
										<Accordion.Body className="pt-0">
											<div className="repair-items-container">
												{repairs
													?.filter(
														(rp) =>
															rp.repairCategory
																.id === cat.id
													)
													.map((repair) => (
														<div
															key={repair.id}
															className="repair-item"
														>
															<div className="text-right mb-1  d-lg-none">
																{repair.isMostChoosen ? (
																	<div
																		className="best-choice-box  px-2 d-inline-block"
																		style={{
																			fontWeight:
																				"normal",
																		}}
																	>
																		<span className="best-choice-text">
																			Meest
																			gekozen
																		</span>
																	</div>
																) : (
																	<></>
																)}
															</div>
															<Row>
																<Col lg={10}>
																	<p className="repair-item-title mb-2">
																		<span className="me-2">
																			{
																				repair.title
																			}
																		</span>

																		<div className="text-right mb-1  d-none d-lg-inline-block">
																			{repair.isMostChoosen ? (
																				<div
																					className="best-choice-box  px-2 d-inline-block"
																					style={{
																						fontWeight:
																							"normal",
																					}}
																				>
																					<span className="best-choice-text">
																						Meest
																						gekozen
																					</span>
																				</div>
																			) : (
																				<>

																				</>
																			)}
																		</div>
																	</p>
																	<p className="repair-item-detail m-0">
																		{
																			repair.description
																		}
																		{repair.readMoreLink ? (
																			<a
																				rel="noreferrer"
																				target="_blank"
																				href={
																					repair.readMoreLink
																				}
																			>
																				{" "}
																				meer
																				info
																			</a>
																		) : undefined}
																	</p>
																	<p className="m-0 mt-2">
																		<span className="m-0 small text-bold">
																			Voorraad:{" "}
																		</span>

																		<span className="small">
																			{StockStatus[
																				repair
																					.stockStatus
																			]?.replace(
																				"_",
																				" "
																			)}
																		</span>
																	</p>
																	<span className="text-bold m-0 small">
																		Reparatietijd:
																	</span>{" "}
																	<span className="small">
																		{" "}
																		{
																			repair.timeTake
																		}
																	</span>
																</Col>
																<Col lg={2}>
																	<div
																		style={{
																			textAlign:
																				"right",
																		}}
																	>
																		<p className="text-bold">
																			{repair.cost
																				? `${CURRENCYSIGN} ${repair.cost?.toFixed(
																						2
																				  )}`
																				: "op aanvraag"}
																		</p>
																		{selectedRepairs?.findIndex(
																			(
																				x
																			) =>
																				x.id ===
																				repair.id
																		) >=
																		0 ? (
																			<FontAwesomeIcon
																				onClick={() =>
																					removeRepair(
																						repair.id
																					)
																				}
																				className="bg-danger btn-remove-repair"
																				icon={
																					faClose
																				}
																				color="white"
																				size="xs"
																			/>
																		) : (
																			<button
																				onClick={() =>
																					addRepair(
																						repair
																					)
																				}
																				className="btn btn-primary add-repair-btn"
																			>
																				<FontAwesomeIcon
																					icon={
																						faAdd
																					}
																				/>
																			</button>
																		)}
																	</div>
																</Col>
															</Row>
														</div>
													))}
											</div>
										</Accordion.Body>
									</Accordion.Item>
								))}
							</Accordion>
						</Col>
						<Col lg={5}>
							<Card id="summary" className="mt-3">
								<Card.Body>
									<h6 className="mt-3">
										Reparatie overzicht
									</h6>
									<p className="mb-1 small text-bold">
										{`${product.name} (${product.model})`}
									</p>
									<p className="small text-bold">
										Reparatie:
									</p>

									{/**Selected repairs */}
									<div>
										{selectedRepairs?.map((repair) => (
											<div
												key={repair.id}
												className="bg-custom p-3 pt-2 pb-2 mb-2"
											>
												<Row>
													<Col xs={8} md={10}>
														<h6>{repair.title}</h6>
														<span className="text-bold m-0 small">
															Reparatietijd:
														</span>{" "}
														<span className="small">
															{repair.timeTake}
														</span>
													</Col>
													<Col
														xs={4}
														md={2}
														style={{
															textAlign: "right",
														}}
													>
														<FontAwesomeIcon
															onClick={() =>
																removeRepair(
																	repair.id
																)
															}
															className="bg-danger btn-remove-repair"
															icon={faClose}
															color="white"
															size="xs"
														/>

														<p className="text-bold">
															{repair.cost
																? `${CURRENCYSIGN} ${repair.cost?.toFixed(
																		2
																  )}`
																: "op aanvraag"}
														</p>
													</Col>
												</Row>
											</div>
										))}
									</div>

									{/**Select Color */}
									<div className="select-color-container">
										<label className="text-bold small">
											Kies kleur (verplicht):
										</label>
										<div className="mt-1">
											{product.colors?.map((color) => (
												<button
													key={color.id}
													onClick={() =>
														setSelectedColor(color)
													}
													className={`select-color-btn`}
													style={{
														backgroundColor:
															color.colorCode,
														WebkitBoxShadow:
															color.id ===
															selectedColor?.id
																? "0px 0px 3px 3px  #a8a8a8"
																: "0px 0px 1px 1px #a8a8a8",
														boxShadow:
															color.id ===
															selectedColor?.id
																? "0px 0px 3px 3px #a8a8a8"
																: "0px 0px 1px 1px #a8a8a8",
													}}
												></button>
											))}
										</div>
									</div>

									<hr
										style={{
											borderStyle: "dashed",
										}}
									/>

									{/**Total */}
									<Row>
										<Col
											style={{
												textAlign: "right",
											}}
										>
											<p className="text-bold mb-0">
												Totaal
											</p>
											<p className="text-bold">{`${CURRENCYSIGN} ${
												selectedRepairs?.length
													? selectedRepairs?.find(
															(x) =>
																x.cost ===
																	undefined ||
																x.cost === null
													  )
														? "op aanvraag"
														: selectedRepairs
																?.map(
																	(x) =>
																		x.cost
																)
																?.reduce(
																	(a, b) =>
																		a! + b!
																)
																?.toFixed(2)
													: "0"
											}`}</p>
										</Col>
									</Row>
									<div className="text-end">
										<small>
											Totale kosten zijn inclusief arbeid,
											onderdeel en btw
										</small>
									</div>

									{error ? (
										<div className="my-4 text-danger text-end">
											{error ?? ""}
										</div>
									) : undefined}

									<div className="text-center text-lg-end mt-3">
										<button
											disabled={!selectedRepairs?.length}
											onClick={() => confirmOrder()}
											className="btn btn-primary"
										>
											Reparatie Aanvraag{" "}
											<FontAwesomeIcon
												size="xl"
												icon={faArrowAltCircleRight}
											/>
										</button>
									</div>
								</Card.Body>
							</Card>

							<div className="mt-3 d-none d-lg-block">
								<Why4Mobile />
							</div>
						</Col>
					</Row>
				</>
			) : undefined}

			<div className="repair-alert-box p-4 mt-5">
				<p className="repair-alert-box-text">
					Om teleurstellingen te voorkomen kunt u contact opnemen voor
					de actuele voorraad. Uitleg voorstatus: Op voorraad, Beperkt
					(1 a 2 dagen levertijd), Slecht (nader overeen te komen).
				</p>
			</div>
			<div className="my-5  d-lg-none">
				<Why4Mobile />
			</div>
			<div
				className="my-5 pt-4"
				dangerouslySetInnerHTML={{
					__html: seoText,
				}}
			></div>
		</Container>
	);
}
