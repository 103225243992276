import ReactStars from "react-rating-stars-component";
import { IGoogleReview } from "../../models/shared-models";
import { useState } from "react";
import UserImg from "../../images/user-30x30.png";

type TReviewProps = {
    review: IGoogleReview;
};

export default function Review(props: TReviewProps) {
    const [imgFailed, setImgFailed] = useState<boolean>(false);
    return (
        <div className="card review-card my-2 h-100">
            <div className="card-body">
                <div className="review-card-header">
                    <div>
                        <img
                            onError={(e) => setImgFailed(true)}
                            className="review-card-img"
                            src={
                                imgFailed
                                    ? UserImg
                                    : props.review.profile_photo_url
                            }
                            alt=""
                        />
                    </div>

                    <div>
                        <ReactStars
                            edit={false}
                            value={props.review.rating}
                            size={25}
                            count={5}
                        />
                    </div>
                </div>
                <p className="mt-2 mb-1 review-card-title">
                    {props.review.author_name}
                </p>
                <p className="review-card-text mb-0">{props.review.text}</p>
            </div>
        </div>
    );
}
