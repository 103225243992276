import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Img1 from "../../images/hiw-1.png";
import Img2 from "../../images/hiw-2.png";
import Img3 from "../../images/hiw-3.png";
import Img4 from "../../images/hiw-4.png";
import "./styles.scss";

export default function HowItWorks() {
    return (
        <div className="py-3 how-it-works-container">
            <p className="device-type-title text-center">Hoe het werkt?</p>
            <p className="text-center">Uw reparatie in 4 eenvoudige stappen</p>
            <Row className="mt-4 g-4">
                <Col sm={6} md={6} lg={3}>
                    <Card className="shadow rounded-4 card-container h-100">
                        <div className="corner">
                            <span>1</span>
                        </div>
                        <Card.Body>
                            <div className="mt-5">
                                <div className="text-center my-3">
                                    <img src={Img1} alt="" />
                                </div>
                                <p className="lead text-center">Afspraak</p>
                                <p className="text-center mb-5">
                                    Kom direct langs of maak eenvoudig een
                                    afspraak.
                                </p>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={6} md={6} lg={3}>
                    <Card className="shadow rounded-4 card-container h-100">
                        <div className="corner">
                            <span>2</span>
                        </div>
                        <Card.Body>
                            <div className="mt-5">
                                <div className="text-center my-3">
                                    <img src={Img2} alt="" />
                                </div>
                                <p className="lead text-center">Analyse</p>
                                <p className="text-center mb-5">
                                    We heten u welkom en lichten de kosten en
                                    werkzaamheden nader toe.
                                </p>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm={6} md={6} lg={3}>
                    <Card className="shadow rounded-4 card-container  h-100">
                        <div className="corner">
                            <span>3</span>
                        </div>
                        <Card.Body>
                            <div className="mt-5">
                                <div className="text-center my-3">
                                    <img src={Img3} alt="" />
                                </div>
                                <p className="lead text-center">De reparatie</p>
                                <p className="text-center mb-5">
                                    Wij gaan voor u aan de slag om tot een
                                    geslaagd eindresultaat te komen.
                                </p>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm={6} md={6} lg={3}>
                    <Card className="shadow rounded-4 card-container  h-100">
                        <div className="corner">
                            <span>4</span>
                        </div>
                        <Card.Body>
                            <div className="mt-5">
                                <div className="text-center my-3">
                                    <img src={Img4} alt="" />
                                </div>
                                <p className="lead text-center">
                                    Het resultaat
                                </p>
                                <p className="text-center mb-5">
                                    Wanneer u tevreden bent,kunt u weer
                                    vliegensvlug op weg met uw apparaat.
                                </p>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
