import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";
import { Row, Col } from "react-bootstrap";
import GoogleReview from "../google-review";
import { useEffect, useState } from "react";
import { MiscApi } from "../../AAL/misc-api";

export default function TopBar() {
    const [address, setAddress] = useState<string>("");
    const [phoneNumbers, setPhoneNumbers] = useState<string>("");

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            var result = await Promise.all([
                MiscApi.getAddress(),
                MiscApi.getPhoneNumbers(),
            ]);
            setAddress(result[0]);
            setPhoneNumbers(result[1]);
        } catch (error) {}
    };
    return (
        <div className="top-bar-container d-none d-lg-block">
            <div className="container">
                <Row className="d-flex">
                    <Col md={4} className="align-self-center">
                        <FontAwesomeIcon
                            style={{
                                marginRight: 10,
                            }}
                            size="lg"
                            icon={faLocationDot}
                            color="white"
                        />
                        <span className="text-white">{address}</span>
                    </Col>
                    <Col md={4} className="align-self-center">
                        <GoogleReview />
                    </Col>
                    <Col md={4} className="align-self-center">
                        <div className="float-end">
                            <FontAwesomeIcon icon={faPhone} color="white" />
                            <span className="text-white right ms-2">
                                {phoneNumbers?.split(",")?.map((n, i) => (
                                    <a
                                        key={i}
                                        style={{
                                            textDecoration: "none",
                                            color: "white",
                                        }}
                                        href={`tel:${n}`}
                                    >
                                        {n}
                                    </a>
                                ))}
                            </span>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
