import { ICreateEnquiryModel, IEnquiryModel } from "../models/enquiry-models";
import { BaseApi } from "./base-api";

export class EnquiryApi extends BaseApi {
    private static url = (api: string) => `enquiry/${api}`;



    /**
     * Make Enquiry
     * @param data
     * @returns {IEnquiryModel} Enquiry
     */
    public static async makeEnquiry(data: ICreateEnquiryModel): Promise<IEnquiryModel> {
        return this._post(this.url('create'), data);
    }

}