import { IFooterMenuBrandItemModel, IFooterMenuProductItemModel } from "../models/footer-menu-models";
import { IFrequentSliderItemModel } from "../models/frequent-slider-item";
import { INavbarMenu } from "../models/navbar-menu-model";
import { INonWorkingDayModel } from "../models/non-working-day-model";
import { IGoogleReview, IGoogleReviewData, IPageContent, ISocialLinks } from "../models/shared-models";
import { BaseApi } from "./base-api";

export class MiscApi extends BaseApi {
    private static url = (api: string) => `sitecontent/${api}`;

    /**
     * Get non working days
     
     * @returns Order
     */
    public static async getNonWokringDays(fromDate: Date, toDate: Date): Promise<INonWorkingDayModel[]> {
        return this._get(this.url('getallnonworkingdays'), {
            fromDate,
            toDate
        });
    }

    /**
     * Get Visit Store Time Slots
     * @returns string[]
     */
    public static async getTimeSlots(): Promise<string[]> {
        return this._get(this.url('gettimeslots'));
    }

    /**
     * Get Frequent Slider Items
     * @returns 
     */
    public static async getFrequentSliderItems(): Promise<IFrequentSliderItemModel[]> {
        return this._get(this.url('gethomepageswiperitem'));
    }

    /**
     * Get Brand Footer Menu list
     * @returns 
     */
    public static async getBrandFooterMenuList(): Promise<IFooterMenuBrandItemModel[]> {
        return this._get(this.url('GetFooterMenuBrandItems'));
    }


    /**
     * Get Footer menu list (Products)
     * @returns 
     */
    public static async getProductFooterMenuList(): Promise<IFooterMenuProductItemModel[]> {
        return this._get(this.url('GetFooterMenuProductItems'));
    }

    /**
     * Get Commercial Page Content
     * @returns 
     */
    public static async getCommercialPageContent(): Promise<IPageContent> {
        return this._get(this.url('GetCommercialPageContent'))
    }


    /**
     * Get about us Page Content
     * @returns 
     */
    public static async getAboutUsPageContent(): Promise<IPageContent> {
        return this._get(this.url('GetAboutUsPageContent'))
    }


    /**
     * Get contact address
     * @returns 
     */
    public static async getAddress(): Promise<string> {
        return this._get(this.url('GetAddress'))
    }


    /**
     * Get phone numbers
     * @returns 
     */
    public static async getPhoneNumbers(): Promise<string> {
        return this._get(this.url('GetPhoneNumbers'))
    }

    /**
     * Get emails
     * @returns 
     */
    public static async getEmails(): Promise<string> {
        return this._get(this.url('getemails'))
    }


    /**
     * Get opening hours
     * @returns 
     */
    public static async getOpeningHours(): Promise<string> {
        return this._get(this.url('getopeninghours'))
    }


    /**
    * Get Terms & conditions Page Content
    * @returns 
    */
    public static async getTermsPageContent(): Promise<IPageContent> {
        return this._get(this.url('GetTermsPageContent'))
    }


    /**
    * Get Privacy Page Content
    * @returns 
    */
    public static async getPrivacyPageContent(): Promise<IPageContent> {
        return this._get(this.url('GetPrivacyPageContent'))
    }


    /**
    * Get Disclaimer Page Content
    * @returns 
    */
    public static async getDisclaimerPageContent(): Promise<IPageContent> {
        return this._get(this.url('GetDisclaimerPageContent'))
    }

    /**
 * Get Navbar menu list
 * @returns 
 */
    public static async getNavbarMenus(): Promise<INavbarMenu[]> {
        return this._get(this.url('GetNavbarMenus'));
    }


    /**
  * Get Faq Page Content
  * @returns 
  */
    public static async getFaqPageContent(): Promise<IPageContent> {
        return this._get(this.url('GetFaqPageContent'))
    }

    /**
  * Get Home Page Content
  * @returns 
  */
    public static async getHomePageContent(): Promise<IPageContent> {
        return this._get(this.url('GetHomePageContent'))
    }

    /**
  * Get Contact Page Content
  * @returns 
  */
    public static async getContactPageContent(): Promise<IPageContent> {
        return this._get(this.url('GetContactPageContent'))
    }

    /**
    * Get social links
    * @returns 
    */
    public static async getSocialLinks(): Promise<ISocialLinks> {
        return this._get(this.url('GetSocialLinks'))
    }



    /**
* Get Service Area Page Content
* @returns 
*/
    public static async getServiceAreasPageContent(): Promise<IPageContent> {
        return this._get(this.url('GetServiceAreasPageContent'))
    }

    /**
* Get Google Reviews
* @returns 
*/
    public static async getGoogleReviews(): Promise<IGoogleReviewData> {
        return this._get(this.url('GetReviews'))
    }


    /**Get Repair Page Seo Text
    * @returns
    */
    public static async getRepairPageSeoText(): Promise<string> {
        return this._get(this.url('GetRepariPageSeoText'))
    }
}