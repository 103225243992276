import {
  RouterProvider,
} from "react-router-dom";
import './App.scss';
import { Routes } from "./router";

function App() {
  return <RouterProvider router={Routes}/>;
}

export default App;
