import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import "./styles.scss";
import { Helmet } from "react-helmet";
import { MiscApi } from "../../AAL/misc-api";
import { IPageContent } from "../../models/shared-models";
import { IRegionalHomePageModel } from "../../models/regional-page-models";
import { RegionalHomePagesApi } from "../../AAL/regional-pages-api";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Card from "react-bootstrap/esm/Card";
import { Link, NavLink } from "react-router-dom";

export default function ServiceAreasPage() {
	const [loading, setLoading] = useState<boolean>(false);
	const [areas, setAreas] = useState<IRegionalHomePageModel[]>([]);
	const [seoContent, setSeoContent] = useState<IPageContent>();

	useEffect(() => {
		MiscApi.getServiceAreasPageContent()
			.then((d) => setSeoContent(d))
			.catch();
		loadData();
	}, []);

	const loadData = async () => {
		try {
			setLoading(true);
			const result = await RegionalHomePagesApi.getAll();
			setAreas(result);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};
	return (
		<Container>
			{seoContent ? (
				<Helmet>
					<title>{seoContent?.title}</title>
					<meta name="description" content={seoContent.description} />
					<meta name="keywords" content={seoContent.keywords} />
				</Helmet>
			) : undefined}
			<nav className="mt-2 mb-2">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<NavLink to="/" className="text-primary">
							Home
						</NavLink>
					</li>
					<li className="breadcrumb-item active">Servicegebied</li>
				</ol>
			</nav>

			{loading ? (
				<div className="text-center my-5">
					<Spinner variant="primary" />
				</div>
			) : (
				<div className="mt-5 faq">
					<h2 className="text-center">Servicegebied</h2>
					<div className="mt-4">
						<Row>
							{areas?.map((a) => (
								<Col key={a.id.toString()} sm={6} md={3} lg={2}>
									<Link
										className="btn btn-lg btn-default w-100 btn-outline-primary rounded-0"
										to={"/region/" + a.slug}
									>
										{a.region}
									</Link>
									<p className="mt-2 text-center">
										{a.pageHeader}
									</p>
								</Col>
							))}
						</Row>
					</div>
				</div>
			)}

			<div
				className="mt-4 ql-editor"
				dangerouslySetInnerHTML={{
					__html: seoContent?.content ?? "",
				}}
			></div>
		</Container>
	);
}
