import { IBlogModel } from "../models/blog-models";
import { BaseApi } from "./base-api";

export class BlogApi extends BaseApi {
    private static url = (api: string) => `blog/${api}`;

    /**
     * Get 
     * @param id id
     * @returns 
     */
    public static async get(slug: string): Promise<IBlogModel> {
        return this._get(this.url('get'), {
            slug
        });
    }

    /**
     * Get all brands
     * @returns List of brands
     */
    public static async getAll(): Promise<IBlogModel[]> {
        return this._get(this.url('getall'));
    }
}