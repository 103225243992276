import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { OrderStatus } from "../../enums/order-status";
import { IRepairItemModel } from "../../models/repair-item-models";
import Card from "react-bootstrap/esm/Card";
import { IOrderModel } from "../../models/order-model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
export type TViewOrderModalProps = {
    item?: IOrderModel;
    show: boolean;
    onHide?: () => void;
};
export default function ViewOrderModal(props: TViewOrderModalProps) {
    const [showRepairs, setShowRepairs] = useState<boolean>(false);

    const onHide = () => {
        if (props?.onHide) {
            props.onHide();
        }
    };

    return (
        <>
            <Modal size="lg" onHide={onHide} show={props.show}>
                <Modal.Header closeButton>
                    <Modal.Title>Order Details</Modal.Title>
                </Modal.Header>
                {props?.item ? (
                    <Modal.Body>
                        <table className="table table">
                            <tbody>
                                <tr>
                                    <td className="text-bold text-right">
                                        Order Number{" "}
                                    </td>
                                    <td>{props.item.orderNumber}</td>
                                    <td className="text-bold text-right">
                                        Date
                                    </td>
                                    <td>
                                        {props.item.addedAt?.toLocaleDateString()}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-bold text-right">
                                        Customer{" "}
                                    </td>
                                    <td>{props.item.name}</td>
                                    <td className="text-bold text-right">
                                        Phone Number
                                    </td>
                                    <td>{props.item.phoneNumber}</td>
                                </tr>
                                <tr>
                                    <td className="text-bold text-right">
                                        Email
                                    </td>
                                    <td>{props.item.email}</td>
                                    <td className="text-bold text-right">
                                        Visiting Store
                                    </td>
                                    <td>
                                        {props.item.visitingStore
                                            ? "Yes"
                                            : "No"}
                                    </td>
                                </tr>
                                {props.item.visitingStore ? (
                                    <tr>
                                        <td className="text-bold text-right">
                                            Visiting Date
                                        </td>
                                        <td>
                                            {props.item.visitingStoreDate?.toLocaleDateString()}
                                        </td>
                                        <td className="text-bold text-right">
                                            Time Slot
                                        </td>
                                        <td>{props.item.visitingStoreTime}</td>
                                    </tr>
                                ) : undefined}

                                <tr>
                                    <td className="text-bold text-right">
                                        Created By
                                    </td>
                                    <td>{props.item?.addedBy}</td>
                                    <td className="text-bold text-right">
                                        Last Update
                                    </td>
                                    <td>
                                        {props.item.updatedAt?.toLocaleDateString()}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-bold text-right">
                                        Repairs
                                    </td>
                                    <td>
                                        {`${props.item?.repairs?.length} Repairs `}
                                        <Button
                                            size="sm"
                                            className=""
                                            variant="info"
                                            onClick={() => setShowRepairs(true)}
                                        >
                                            <FontAwesomeIcon
                                                icon={faEye}
                                                color="white"
                                            />
                                        </Button>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </Modal.Body>
                ) : undefined}
                <Modal.Footer>
                    <Button onClick={onHide} variant="success">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showRepairs} onHide={() => setShowRepairs(false)}>
                <Modal.Header closeButton>Repairs</Modal.Header>
                <Modal.Body>
                    <div
                        style={{
                            maxHeight: "300px",
                            overflowY: "auto",
                        }}
                    >
                        {props.item?.repairs.map((r) => (
                            <RepairListItem
                                key={r.id}
                                type="view"
                                item={r.repairItem}
                            />
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

type TProps = {
    item: IRepairItemModel;
    type: "add" | "remove" | "view";
};

function RepairListItem(props: TProps) {
    return (
        <>
            <Card
                style={{
                    backgroundColor: "#efefef",
                }}
                className="p-2 mt-1"
            >
                <div className="d-flex flex-row">
                    <div className="flex-fill pr-3">
                        <p className="m-0 p-0">{props.item.title}</p>
                        <small className="p-0 m-0">
                            {props.item.description}
                        </small>
                        <div className="d-flex flex-row justify-content-between">
                            <div>
                                <strong>Cost: </strong> {props.item.cost}
                            </div>
                            <div>
                                <strong>Time: </strong> {props.item.timeTake}{" "}
                                minutes
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
}
