import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { FAQApi } from "../../AAL/faq-api";
import { IFAQModel } from "../../models/faq-models";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

import "./styles.scss";
import { FAQSubject } from "../../enums/faq-subject";
import { Helmet } from "react-helmet";
import { MiscApi } from "../../AAL/misc-api";
import { IPageContent } from "../../models/shared-models";
import EnquiryForm from "../../components/enquiry-form";
import { Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";
export default function FAQPage() {
	const [loading, setLoading] = useState<boolean>(false);
	const [faqs, setFaqs] = useState<IFAQModel[]>([]);
	const [seoContent, setSeoContent] = useState<IPageContent>();

	useEffect(() => {
		MiscApi.getFaqPageContent()
			.then((d) => setSeoContent(d))
			.catch();
		loadData();
	}, []);

	const loadData = async () => {
		try {
			setLoading(true);
			const result = await FAQApi.getAll();
			setFaqs(result);
			window.scrollTo(0, 0);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};
	return (
		<Container>
			{seoContent ? (
				<Helmet>
					<title>{seoContent?.title}</title>
					<meta name="description" content={seoContent.description} />
					<meta name="keywords" content={seoContent.keywords} />
				</Helmet>
			) : undefined}
			{/**Breadcrumb */}
			<nav className="mt-2 mb-2">
				<ol className="breadcrumb">
					<li className="breadcrumb-item active">
						<NavLink to="/" className="text-primary">
							Home
						</NavLink>
					</li>
					<li className="breadcrumb-item active">
						Veelgestelde vragen
					</li>
				</ol>
			</nav>
			{loading ? (
				<div className="text-center my-5">
					<Spinner variant="primary" />
				</div>
			) : (
				<div className="faq">
					<div
						className="mb-4 ql-editor"
						dangerouslySetInnerHTML={{
							__html: seoContent?.content ?? "",
						}}
					></div>
					<Tab.Container defaultActiveKey={"all"}>
						<Row>
							<Col md={4} lg={3}>
								<div>
									<p className="lead mx-2 px-3 bold pb-2 text-primary border-bottom">
										Onderwerp
									</p>

									<Nav
										variant="pills"
										className="flex-column faq-nav"
									>
										<Nav.Item key="f_all">
											<Nav.Link eventKey="all">
												Alle Vragen
											</Nav.Link>
										</Nav.Item>
										{Object.keys(FAQSubject)
											.filter(
												(item) => !isNaN(Number(item))
											)
											.map((item, i) => (
												<Nav.Item key={i}>
													<Nav.Link eventKey={item}>
														{FAQSubject[
															Number(item)
														]?.replace("_", " ")}
													</Nav.Link>
												</Nav.Item>
											))}
									</Nav>
								</div>
							</Col>
							<Col md={8} lg={9}>
								<div className="mt-5 mt-md-0 pt-5">
									<Tab.Content>
										<Tab.Pane eventKey={"all"}>
											<Accordion
												defaultActiveKey="0"
												flush
											>
												{faqs?.map((faq, i) => (
													<Accordion.Item
														eventKey={i.toString()}
														key={i}
													>
														<Accordion.Header>
															{faq.question}
														</Accordion.Header>
														<Accordion.Body>
															{faq.answer}
															{faq.readMoreLink ? (
																<a
																	rel="noreferrer"
																	target="_blank"
																	href={
																		faq.readMoreLink
																	}
																>
																	{" "}
																	meer info
																</a>
															) : undefined}
														</Accordion.Body>
													</Accordion.Item>
												))}
											</Accordion>
										</Tab.Pane>
										{Object.keys(FAQSubject)
											.filter(
												(item) => !isNaN(Number(item))
											)
											.map((sub, i) => (
												<Tab.Pane
													key={i}
													eventKey={sub}
												>
													<Accordion flush>
														{faqs
															.filter(
																(faq) =>
																	faq.subject ==
																	(sub as any as FAQSubject)
															)
															?.map((faq, i) => (
																<Accordion.Item
																	eventKey={i.toString()}
																	key={i}
																>
																	<Accordion.Header>
																		{
																			faq.question
																		}
																	</Accordion.Header>
																	<Accordion.Body>
																		{
																			faq.answer
																		}
																		{faq.readMoreLink ? (
																			<a
																				rel="noreferrer"
																				target="_blank"
																				href={
																					faq.readMoreLink
																				}
																			>
																				{" "}
																				meer
																				info
																			</a>
																		) : undefined}
																	</Accordion.Body>
																</Accordion.Item>
															))}
													</Accordion>
												</Tab.Pane>
											))}
									</Tab.Content>
								</div>
							</Col>
						</Row>
					</Tab.Container>
				</div>
			)}
			<hr />
			<Row>
				<Col className="mx-auto" md={6}>
					<Card className="bg-primary text-white my-5">
						<Card.Body>
							<EnquiryForm />
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
